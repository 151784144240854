import React from 'react';
import { useTranslation } from 'react-i18next';
import { ControlBarButton } from '@m12s/component-library';
import { user, playRegular } from 'lib/icons';
import Routes from 'lib/utils/routes';
import { UtilityMenu } from 'components/UtilityMenu';
import {
  ControlBarResponsive,
  MobileIcon,
  ResponsiveText,
  Scrollable,
} from '../styled';
import { CategorizeDowntime } from './CategorizeDowntime';
import { SwitchMachine } from './SwitchMachine';

const SignInAndSelectJob = ({
  history,
  machine,
  operator,
  hasOpenActivitySet,
  enableMachineSwitching = true,
}) => {
  const { t } = useTranslation();

  // Signed Out, no job running
  if (!operator)
    return (
      <ControlBarResponsive id="job-control-bar">
        <Scrollable>
          <ControlBarButton
            id="job-control-bar-sign-in"
            onClick={() => {
              if (!hasOpenActivitySet) {
                history.push(Routes.machineIdLoginPath(machine.id));
                return history.push(Routes.machineIdSelectJobPath(machine.id));
              }

              return history.push(Routes.machineIdLoginPath(machine.id));
            }}
          >
            <MobileIcon icon={user} />
            <ResponsiveText
              full={t('signIn|full')}
              medium={t('signIn|medium')}
              short={t('signIn|short')}
            />
          </ControlBarButton>
          {enableMachineSwitching && <SwitchMachine history={history} />}
        </Scrollable>
      </ControlBarResponsive>
    );

  // Signed In, no job running
  return (
    <ControlBarResponsive id="job-control-bar">
      <UtilityMenu />

      <Scrollable>
        <ControlBarButton
          id="job-control-start-job"
          onClick={() => {
            return history.push(Routes.machineIdSelectJobPath(machine.id));
          }}
        >
          <MobileIcon icon={playRegular} />
          <ResponsiveText
            full={t('startJob|full')}
            medium={t('startJob|medium')}
            short={t('startJob|short')}
          />
        </ControlBarButton>
        <CategorizeDowntime history={history} machine={machine} />
      </Scrollable>
    </ControlBarResponsive>
  );
};

export { SignInAndSelectJob };
