import React from 'react';
import PropTypes from 'prop-types';
import LaborTicketBaseActionModal from 'components/LaborTicket/LaborTicketBaseActionModal';
import { palette } from '@m12s/component-library';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ModalButton } from './styled';

const ModalDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// TODO: missing translations
const LaborTicketMarkAsCompleteModal = ({
  isOpen,
  handleClose,
  handleCancel,
  handleMarkAsComplete,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {isOpen && (
        <LaborTicketBaseActionModal
          isOpen={isOpen}
          onClose={handleClose}
          header={t('Mark this job as complete?')}
          details={
            <ModalDetailsContainer>
              <div>
                {t(`Our system has determined that the required parts are still
                missing. Completing this production order operation will
                finalize it and remove it from the production schedule.`)}
              </div>

              <strong>{t(`This action cannot be undone.`)}</strong>
            </ModalDetailsContainer>
          }
          buttons={[
            <ModalButton
              key="cancel-button"
              variant="inferior"
              onClick={() => {
                return handleCancel();
              }}
            >
              {t('Cancel')}
            </ModalButton>,
            <ModalButton
              key="mark-complete-button"
              customStyles={{
                backgroundColor: palette.Red700,
              }}
              hoverStyles={{
                backgroundColor: palette.Red800,
              }}
              onClick={() => {
                return handleMarkAsComplete();
              }}
            >
              {t('Mark complete')}
            </ModalButton>,
          ]}
        />
      )}
    </>
  );
};

LaborTicketMarkAsCompleteModal.propTypes = {
  isOpen: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleMarkAsComplete: PropTypes.func,
  handleClose: PropTypes.func,
};

export default LaborTicketMarkAsCompleteModal;
