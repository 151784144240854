// React and Hooks
import React from 'react';
import { useTranslation } from 'react-i18next';
// Actions
// Selectors
// Utils
import PropTypes from 'prop-types';
// Components
import LaborTicketBaseActionModal from 'components/LaborTicket/LaborTicketBaseActionModal';
import { ModalButton } from 'components/LaborTicket/styled';
import { palette } from '@m12s/component-library';

const SetupStopJobOrPauseModal = ({
  isSignOut,
  isOpen,
  handleConfirmStop,
  handlePause,
  handleClose,
}) => {
  const { t } = useTranslation();

  return (
    isOpen && (
      <LaborTicketBaseActionModal
        onClose={handleClose}
        isOpen={isOpen}
        header={t('setupStopJobOrPauseModal|header|default')}
        details={
          <div>
            {t(
              `setupStopJobOrPauseModal|details|${
                isSignOut ? 'withSignOut' : 'default'
              }`
            )}
          </div>
        }
        buttons={[
          <ModalButton
            key="endSetupButton"
            customStyles={{
              backgroundColor: palette.Grey200,
              color: palette.Grey700,
            }}
            hoverStyles={{
              backgroundColor: palette.Grey300,
            }}
            onClick={handleConfirmStop}
          >
            {t('setupStopJobOrPauseModal|buttons|endSetup')}
          </ModalButton>,
          <ModalButton
            key="pauseButton"
            customStyles={{
              backgroundColor: palette.Indigo600,
              color: palette.White,
            }}
            hoverStyles={{
              backgroundColor: palette.Indigo700,
            }}
            onClick={handlePause}
          >
            {t('setupStopJobOrPauseModal|buttons|pause')}
          </ModalButton>,
        ]}
      />
    )
  );
};

SetupStopJobOrPauseModal.propTypes = {
  isSignOut: PropTypes.bool,
  isOpen: PropTypes.bool,
  handleConfirmStop: PropTypes.func,
  handlePause: PropTypes.func,
  handleClose: PropTypes.func,
};

export default SetupStopJobOrPauseModal;
