import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { LABOR_TICKET_ACTIONS } from 'lib/constants';
import { getHasOpenSetupActivity } from 'lib/selectors/getHasOpenSetupActivity';
import { getLaborTicketAction } from 'lib/selectors/getLaborTicketAction';

import LaborTicketStopOrLeaveModal from './LaborTIcketStopOrLeaveModal';

const useProductionLaborTicketStopOrLeaveModal = ({
  laborTicketMarkedAsComplete,
  handleStopJob,
  handleLeaveOpen,
  handleClose,
  handleStopJobAndSignOut,
  enableStopJobAndSignOut,
}) => {
  const [stopOrLeaveModalOpen, setStopOrLeaveModalOpen] = useState(false);

  const laborTicketAction = useSelector(getLaborTicketAction);
  const hasOpenSetupActivity = useSelector(getHasOpenSetupActivity);

  // Request confirmation to stop or leave if:
  // - The labor ticket is incomplete
  // - The labor ticket action is 'Confirm Stop or Continue'
  // - There is an open production activity
  // - The labor ticket is not fullfilled
  const requestStopOrLeaveConfirmation = useMemo(() => {
    return (
      [
        LABOR_TICKET_ACTIONS.STOP_AND_CREATE,
        LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT,
      ].includes(laborTicketAction) &&
      hasOpenSetupActivity !== true &&
      !laborTicketMarkedAsComplete
    );
  }, [laborTicketAction, hasOpenSetupActivity, laborTicketMarkedAsComplete]);

  const modal =
    stopOrLeaveModalOpen && requestStopOrLeaveConfirmation ? (
      <LaborTicketStopOrLeaveModal
        enableStopJobAndSignOut={enableStopJobAndSignOut}
        handleClose={() => {
          setStopOrLeaveModalOpen(false);
          return handleClose ? handleClose() : null;
        }}
        isOpen={stopOrLeaveModalOpen}
        handleStopJob={() => {
          setStopOrLeaveModalOpen(false);
          return handleStopJob();
        }}
        handleLeaveOpen={async () => {
          setStopOrLeaveModalOpen(false);
          return handleLeaveOpen();
        }}
        handleStopJobAndSignOut={() => {
          setStopOrLeaveModalOpen(false);
          return handleStopJobAndSignOut();
        }}
      />
    ) : null;

  return [modal, setStopOrLeaveModalOpen, requestStopOrLeaveConfirmation];
};

useProductionLaborTicketStopOrLeaveModal.propTypes = {
  laborTicketMarkedAsComplete: PropTypes.bool.isRequired,
  handleStopJob: PropTypes.func.isRequired,
  handleLeaveOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  handleStopJobAndSignOut: PropTypes.func.isRequired,
  enableStopJobAndSignOut: PropTypes.bool.isRequired,
};

export default useProductionLaborTicketStopOrLeaveModal;
