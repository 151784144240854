import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  useEmbeddedEffect,
  MMEmbeddableZone,
  getAppInfo,
} from '@machinemetrics/mm-react-embeddable';
import { throwSuccessToast } from 'lib/utils/toast';
import { useDispatch, useSelector } from 'react-redux';
import { actionSetPopupStack } from 'lib/actions';
import { getLatestJobId } from 'lib/selectors/getLatestJobId';
import { getMachine } from 'lib/selectors/getMachine';
import { getScopeNetPartsMade } from 'lib/selectors/getScopeNetPartsMade';
import debounce from 'lodash/debounce';
import _startCase from 'lodash/startCase';
import { Button, palette } from '@m12s/component-library';
import {
  Body,
  Buttons,
  Dialog,
  Header,
  HeaderIcon,
  Title,
} from 'components/Dialog';
import { useTranslation } from 'react-i18next';
import { signIn } from 'lib/icons';
import { H5, P, Text } from 'components/Text';
import styled from 'styled-components';

const HStack = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
`;

const CustomTabZone = ({ id, src, base }) => {
  const { t } = useTranslation();

  const jobId = useSelector(getLatestJobId);
  const machine = useSelector(getMachine);
  const scopeNetPartsMade = useSelector(getScopeNetPartsMade);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [path, setPath] = useState();
  const [loginPayloads, setLoginPayloads] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const debouncedSetLoginPayloads = useCallback(
    debounce((payload) => {
      (async () => {
        const appInfo = await getAppInfo(payload.authUrl);
        setLoginPayloads((prev) => {
          const exists = prev.some((p) => {
            return p.authUrl === payload.authUrl;
          });

          if (exists) {
            return prev;
          }

          return [
            ...prev,
            {
              ...appInfo,
              ...payload,
            },
          ];
        });
      })();
    }, 1000),
    []
  );

  useEffect(() => {
    if (loginPayloads.length > 0) {
      setIsModalOpen(true);
    }
  }, [loginPayloads]);

  useEmbeddedEffect(id, 'login', (data) => {
    debouncedSetLoginPayloads(data);
  });

  const context = useMemo(() => {
    return {
      operationId: jobId,
      machineId: machine?.id,
      machineRef: machine?.machineRef,
      partCount: scopeNetPartsMade,
    };
  }, [jobId, machine, scopeNetPartsMade]);

  useEmbeddedEffect(id, 'set-path', (data) => {
    if (data.path.indexOf('/') === 0) {
      const newPath = `${base}${data.path}`.replace(/\/$/, '');
      history.push(newPath);
    } else {
      const u = new URL(data.path);
      const p = u.pathname.replace(/^\/app/, '');
      history.push(`${p}${u.search}`);
    }
  });

  useEmbeddedEffect(id, 'toast', ({ message }) => {
    throwSuccessToast(message);
  });

  useEmbeddedEffect(id, 'popup', ({ message }) => {
    dispatch(
      actionSetPopupStack({
        params: {
          message,
          popupType: 'message',
          order: 3,
        },
      })
    );
  });

  useEffect(() => {
    if (!location || !src) {
      return;
    }

    const u = new URL(src);
    const regex = new RegExp(`^${base}`);

    if (location.pathname.indexOf(base) !== 0) {
      return;
    }

    const p = location.pathname.replace(regex, u.pathname.replace(/\/$/, ''));
    setPath(p + location.search);
  }, [src, location, base, id]);

  return (
    <>
      <MMEmbeddableZone id={id} src={src} path={path} context={context} />
      <Dialog
        isOpen={isModalOpen}
        onClose={() => {
          return setIsModalOpen(false);
        }}
      >
        <Header>
          <Title color={palette.White}>
            {_startCase(t(`authorization required`))}
          </Title>
          <HeaderIcon color={palette.White} icon={signIn} />
        </Header>
        <Body>
          <P>
            {loginPayloads.length > 0 &&
              t(`The following apps require authorization. Authorize each app
                below to continue.`)}
            {loginPayloads.length === 0 && t(`You're ready to go!`)}
          </P>
          {loginPayloads.map((payload) => {
            return (
              <HStack style={{ padding: '1rem 0' }} key={payload.origin}>
                <div style={{ marginRight: '2rem' }}>
                  <H5 style={{ margin: '0 0 1rem 0' }}>{payload.name}</H5>
                  <Text>{payload.description}</Text>
                </div>
                <div style={{ display: 'flex' }}>
                  <Button
                    style={{ margin: 'auto' }}
                    onClick={() => {
                      const popup = window.open(payload.authUrl, '_blank');

                      const interval = setInterval(() => {
                        if (popup.closed) {
                          clearInterval(interval);
                          setLoginPayloads((prev) => {
                            return prev.filter((p) => {
                              return p.authUrl !== payload.authUrl;
                            });
                          });
                        }
                      }, 1000);
                    }}
                  >
                    {_startCase(t(`authorize`))}
                  </Button>
                </div>
              </HStack>
            );
          })}
        </Body>
        <Buttons>
          <Button
            disabled={loginPayloads.length > 0}
            onClick={() => {
              window.location.reload();
            }}
          >
            Done
          </Button>
        </Buttons>
      </Dialog>
    </>
  );
};

export default CustomTabZone;
