// React and Hooks
import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useSubmitLaborTicket from 'components/LaborTicket/useSubmitLaborTicket';
// Actions
import {
  actionOpenLaborTicketModal,
  actionSetRecentOperatorRuns,
} from 'lib/actions';

// Selectors
import { getMachine } from 'lib/selectors/getMachine';
import { getCanCloseLaborTicket } from 'lib/selectors/getCanOpenLaborTicket';
import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';
import { getIsAPMEnabled } from 'lib/selectors/getIsAPMEnabled';
// Utils
import { now, toISO } from 'lib/utils/date';
import { START_OPERATOR } from 'lib/api/mutations';
import { FLAG_KEYS, LABOR_TICKET_ACTIONS } from 'lib/constants';
// Components
import { exclamationTriangle } from 'lib/icons';
import { Notification, toast } from '@m12s/component-library';

const toastOptions = {
  position: toast.POSITION.BOTTOM_RIGHT,
  autoClose: 1500,
};

/**
 * This hook will return a function to handle
 * onSubmit for the force login modal
 * Redirect path should be from Routes.yourPathHere(machine.id)
 * @param {string} redirectPath
 * @returns {function} onSubmit
 */
const useForceLoginOnSubmit = ({ redirectPath }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const flags = useFlags();
  const requestLaborTicketAction = useSubmitLaborTicket();

  const [startOperator] = useMutation(START_OPERATOR, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ startOperatorResponse }) => {
      if (startOperatorResponse) {
        const { machine, operator, ...operatorRun } = startOperatorResponse;
        const mappedPayload = {
          operatorRuns: [
            {
              ...operatorRun,
              machineId: machine?.id,
              operatorId: operator?.id,
            },
          ],
        };
        dispatch(actionSetRecentOperatorRuns(mappedPayload));
      }
    },
  });

  const machine = useSelector(getMachine);
  const canCloseLaborTicket = useSelector(getCanCloseLaborTicket);
  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);
  const isAPMEnabled = useSelector(getIsAPMEnabled);

  const isLaborTicketEnabled = flags[FLAG_KEYS.LABOR_TICKET_ENABLED];

  const onSubmit = useCallback(
    async (operator, opts) => {
      const nowISO = toISO(now().valueOf());
      try {
        // Start Operator Mutation
        const { errors } = await startOperator({
          variables: {
            input: {
              machineId: machine.id,
              operatorId: operator.id,
              pinToPreviousEvent: opts.pinToPreviousEvent,
              startAt: opts.startAt || nowISO,
            },
          },
        });

        // handle graphql errors
        if (errors) {
          return toast(
            <Notification
              icon={exclamationTriangle}
              text={t('Something went wrong')}
              status="error"
            />,
            toastOptions
          );
        }

        // success cases
        if (canCloseLaborTicket) {
          dispatch(
            // may expand hook to take in action params
            actionOpenLaborTicketModal(LABOR_TICKET_ACTIONS.OPERATOR_LOGIN)
          );
          return history.push(redirectPath);
        }

        if (
          operator?.erpId &&
          currentWorkOrderOp &&
          isLaborTicketEnabled &&
          !isAPMEnabled
        ) {
          // may expand hook to take in action params
          return requestLaborTicketAction({
            action: LABOR_TICKET_ACTIONS.OPERATOR_LOGIN,
            opts: {
              startAt: nowISO,
              loggingInOperator: operator.id,
            },
          });
        }

        return history.push(redirectPath);
      } catch (error) {
        const { networkError } = error;
        if (networkError) {
          const { result } = networkError;
          const { message, errors = [] } = result;
          switch (true) {
            case errors.length: {
              return errors.map(({ message: errMessage }) => {
                return toast(
                  <Notification
                    icon={exclamationTriangle}
                    text={errMessage}
                    status="error"
                  />,
                  toastOptions
                );
              });
            }
            case message: {
              return toast(
                <Notification
                  text={message}
                  status="error"
                  icon={exclamationTriangle}
                />,
                {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 1500,
                }
              );
            }
            default:
              return null;
          }
        }
        return null;
      }
    },
    [
      redirectPath,
      dispatch,
      history,
      startOperator,
      t,
      machine.id,
      currentWorkOrderOp,
      canCloseLaborTicket,
      isAPMEnabled,
      isLaborTicketEnabled,
      requestLaborTicketAction,
    ]
  );
  return onSubmit;
};

export { useForceLoginOnSubmit };
