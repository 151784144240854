import { toISO } from 'lib/utils/date';
import Routes from 'lib/utils/routes';
import { getLatestActivity } from 'lib/selectors/getLatestActivity';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import {
  actionCanonicalActivityUpdate,
  actionOptimisticActivityUpdate,
  actionCloseLaborTicketModal,
} from 'lib/actions';
import { UPDATE_ACTIVITY } from 'lib/api/mutations';
import { throwErrorToast, throwSuccessToast } from 'lib/utils/toast';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getMachine } from 'lib/selectors/getMachine';
import { useTranslation } from 'react-i18next';

const useHandlePauseOperation = ({ onPaused }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const storeDispatch = useDispatch();

  //   const scopeNetPartsMade = useSelector(getNetSetupParts);
  const latestActivitySet = useSelector(getLatestActivitySet);
  const latestActivity = useSelector(getLatestActivity);
  const machine = useSelector(getMachine);

  const [requestUpdateActivity] = useMutation(UPDATE_ACTIVITY, {
    fetchPolicy: 'no-cache',
    update: (
      _cache,
      {
        data: {
          updateActivityResponse: { activity },
        },
      }
    ) => {
      storeDispatch(actionOptimisticActivityUpdate({ activity }));
      history.push(Routes.machineIdPath(machine.id));
    },
    onCompleted: ({ updateActivityResponse: { activity } }) => {
      throwSuccessToast(t('Successfully Paused'));
      storeDispatch(actionCanonicalActivityUpdate({ activity }));
      storeDispatch(actionCloseLaborTicketModal());
      if (onPaused) onPaused();
    },
    onError: () => {
      storeDispatch(
        actionCanonicalActivityUpdate({
          activity: {
            activityRef: latestActivity?.activityRef,
            end: null,
          },
        })
      );
      history.push(Routes.machineIdPath(machine.id));
      throwErrorToast(t('Failed to Pause'));
    },
  });

  return () => {
    const now = toISO();
    // adjustParts(formState?.parts - scopeNetPartsMade);
    return requestUpdateActivity({
      variables: {
        input: {
          activitySetRef: latestActivitySet.activitySetRef,
          activityRef: latestActivity.activityRef,
          endAt: now,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateActivityResponse: {
          __typename: 'Activity',
          activity: {
            ...latestActivity,
            end: now,
            updatedAt: now,
            jobScheduleGroupId: null,
          },
        },
      },
    });
  };
};

export default useHandlePauseOperation;
