import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { LABOR_TICKET_ACTIONS } from 'lib/constants';
import { getHasOpenSetupActivity } from 'lib/selectors/getHasOpenSetupActivity';
import { getLaborTicketAction } from 'lib/selectors/getLaborTicketAction';

import LaborTicketMarkAsCompleteModal from './LaborTicketMarkAsCompleteModal';

const useLaborTicketMarkAsCompleteModal = ({
  laborTicketMarkedAsComplete,
  laborTicketFullfilled,
  handleCancel,
  handleMarkAsComplete,
  handleClose,
}) => {
  const [markAsCompleteModalOpen, setMarkAsCompleteModalOpen] = useState(false);

  const laborTicketAction = useSelector(getLaborTicketAction);
  const hasOpenSetupActivity = useSelector(getHasOpenSetupActivity);

  const requestMarkAsCompleteConfirmation = useMemo(() => {
    return (
      [
        LABOR_TICKET_ACTIONS.STOP_AND_CREATE,
        LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT,
      ].includes(laborTicketAction) &&
      hasOpenSetupActivity !== true &&
      !laborTicketFullfilled &&
      laborTicketMarkedAsComplete
    );
  }, [
    laborTicketAction,
    hasOpenSetupActivity,
    laborTicketMarkedAsComplete,
    laborTicketFullfilled,
  ]);

  const modal =
    markAsCompleteModalOpen && requestMarkAsCompleteConfirmation ? (
      <LaborTicketMarkAsCompleteModal
        isOpen={markAsCompleteModalOpen}
        handleClose={() => {
          setMarkAsCompleteModalOpen(false);
          return handleClose ? handleClose() : null;
        }}
        handleCancel={() => {
          setMarkAsCompleteModalOpen(false);
          return handleCancel ? handleCancel() : null;
        }}
        handleMarkAsComplete={async () => {
          setMarkAsCompleteModalOpen(false);
          return handleMarkAsComplete();
        }}
      />
    ) : null;

  return [modal, setMarkAsCompleteModalOpen, requestMarkAsCompleteConfirmation];
};

useLaborTicketMarkAsCompleteModal.propTypes = {
  laborTicketMarkedAsComplete: PropTypes.bool.isRequired,
  laborTicketFullfilled: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func,
  handleMarkAsComplete: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
};

export default useLaborTicketMarkAsCompleteModal;
