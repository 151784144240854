// React and Hooks
import React from 'react';
import { useTranslation } from 'react-i18next';
// Actions
// Selectors
// Utils
import PropTypes from 'prop-types';
// Components
import { palette } from '@m12s/component-library';
import LaborTicketBaseActionModal from 'components/LaborTicket/LaborTicketBaseActionModal';

import { ModalButton } from './styled';

const SetupConfirmStopJobModal = ({
  isOpen,
  handleCancel,
  handleStopJob,
  handleClose,
}) => {
  const { t } = useTranslation();

  return (
    isOpen && (
      <LaborTicketBaseActionModal
        isOpen={isOpen}
        header={t('setupConfirmStopJobModal|header')}
        onClose={handleClose}
        details={<div>{t('setupConfirmStopJobModal|details')}</div>}
        buttons={[
          <ModalButton
            key="cancelButton"
            customStyles={{
              backgroundColor: palette.Grey200,
              color: palette.Grey700,
            }}
            hoverStyles={{
              backgroundColor: palette.Grey300,
            }}
            onClick={handleCancel}
          >
            {t('setupConfirmStopJobModal|buttons|cancel')}
          </ModalButton>,
          <ModalButton
            key="endSetupButton"
            customStyles={{
              backgroundColor: palette.Red700,
              color: palette.White,
            }}
            hoverStyles={{
              backgroundColor: palette.Red800,
            }}
            onClick={handleStopJob}
          >
            {t('setupConfirmStopJobModal|buttons|endSetup')}
          </ModalButton>,
        ]}
      />
    )
  );
};

SetupConfirmStopJobModal.propTypes = {
  isOpen: PropTypes.bool,
  handleStopJob: PropTypes.func,
  handleClose: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default SetupConfirmStopJobModal;
