import { createSelector } from 'reselect';
import { getOperators } from './getOperators';

const getUnattendedOperatorPersonId = createSelector(
  [getOperators],
  (operators) => {
    return (
      operators.find((op) => {
        return op.name === 'Unattended';
      })?.erpId || null
    );
  }
);

export { getUnattendedOperatorPersonId };
