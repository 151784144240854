import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlBarButton } from '@m12s/component-library';
import { comment } from 'lib/icons';
import { useSelector } from 'react-redux';
import Routes from 'lib/utils/routes';
import { getShiftDowntimeIntervals } from 'lib/selectors/getShiftDowntimeIntervals';
import { getShopDayDowntimeIntervals } from 'lib/selectors/getShopDayDowntimeIntervals';
import { checkIsLatestShiftActive } from 'lib/selectors/checkIsLatestShiftActive';
import { getLongDurationCutoff } from 'lib/api/getLongDurationCutoff';
import { ButtonBadge, MobileIcon, ResponsiveText } from '../styled';

const CategorizeDowntime = ({ history, machine, text }) => {
  const { t } = useTranslation();

  const shiftDowntimeIntervals = useSelector(getShiftDowntimeIntervals);
  const shopDayDowntimeIntervals = useSelector(getShopDayDowntimeIntervals);
  const isLatestShiftActive = useSelector(checkIsLatestShiftActive);
  const downtimeIntervals = isLatestShiftActive
    ? shiftDowntimeIntervals
    : shopDayDowntimeIntervals;
  const longDurationCutoff = useSelector(getLongDurationCutoff);

  const totalUncategorized = useMemo(() => {
    return downtimeIntervals.filter(({ categorized, duration }) => {
      return !categorized && duration > longDurationCutoff;
    }).length;
  }, [downtimeIntervals, longDurationCutoff]);

  return (
    <ControlBarButton
      id="job-control-categorize"
      onClick={() => {
        return history.push({
          pathname: Routes.machineIdCategorizePath(machine.id),
          state: {
            returnPath: history.location.pathname,
          },
        });
      }}
    >
      {totalUncategorized ? (
        <ButtonBadge>{totalUncategorized}</ButtonBadge>
      ) : (
        <MobileIcon icon={comment} />
      )}
      <ResponsiveText
        full={t(`${text || 'categorizeDowntime'}|full`)}
        medium={t(`${text || 'categorizeDowntime'}|medium`)}
        short={t(`${text || 'categorizeDowntime'}|short`)}
      />
    </ControlBarButton>
  );
};

export { CategorizeDowntime };
