const MACHINE_STATUSES = {
  ACTIVE: 'active',
  FEED_HOLD: 'idle',
  IDLE: 'idle',
  INTERRUPTED: 'idle',
  OPTIONAL_STOP: 'idle',
  PROGRAM_COMPLETED: 'idle',
  PROGRAM_STOPPED: 'idle',
  PROGRAM_OPTIONAL_STOP: 'idle',
  READY: 'idle',
  STOPPED: 'idle',
  UNAVAILABLE: 'unavailable',
};

const JOB_STATUSES = {
  SETUP: 'setup',
  PRODUCTION: 'production',
};

const ERROR_KEYS = {
  ANNOTATION: 'annotation',
  JOB_RUN: 'production run',
  PART_ADJUSTMENTS: 'part adjustments',
  JOB_QUANTITY: 'operation quantity',
  INITIAL: 'initial',
  ACCOUNT: 'account',
  MACHINE: 'machine',
  DOWNTIME: 'downtime',
  RECENT_ACTIVITY_SETS: 'recent activity sets',
  RECENT_OPERATOR_RUNS: 'recent operator runs',
  CURRENT_EXPECTED_SHIFT: 'current expected shift',
  OPERATORS: 'operators',
  ACTIVITY_TYPES: 'activities',
  PART_BUCKETS: 'part buckets',
  WORKFLOWS: 'workflows',
  SETUP_PART_BUCKETS: 'setup parts',
  SHIFT_SETUP_PART_BUCKETS: 'shift setup parts',
  LABS_JWT: 'labs token',
  AGENT_STATUS: 'agent status',
  MACHINE_GROUPS: 'machine groups',
  WORK_ORDER_OPS: 'production order operations',
  LABOR_TICKET_PART_ADJ: 'labor ticket part scoped adjustment',
  ERP_RESOURCE: 'erp resource',
  CURRENT_WORK_ORDER: 'current production order',
  WORK_ORDER_OPERATION_PARTS: 'total work order operation parts',
};

const ACTIVITY_MODES = {
  SETUP: 'setup',
  PRODUCTION: 'production',
};

const POLL_INTERVAL = 15000;
const HEAVY_UPDATE_INTERVAL = 10000;
const LIGHT_UPDATE_INTERVAL = 100000;
const THIRTY_SECOND_INTERVAL = 30000;
const SIXTY_SECOND_INTERVAL = 60000;
const LAB_REFRESH_DURATION = 1000 * 60 * 4;

const NETWORK_ERROR_RETRY_TIMEOUT = 10 * 1000;

const LONG_SCOPE_HOURS = 12;

const TEMP_REFS = {
  ACTIVITY_REF: 'temp-activity-ref-0',
  ACTIVITY_SET_REF: 'temp-activity-set-ref-0',
};

const CONTROL_ACTIONS = {
  PAUSE: 'pause',
  STOP: 'stop',
  RESUME: 'resume',
  SWITCH_TO_SETUP: 'switchToSetup',
  SWITCH_TO_PRODUCTION: 'switchToProduction',
};

const MAX_QUERY_BOUNDARY_HOURS = 24;

const DEFAULT_TABS = [
  {
    id: 'cycleCount',
    enabled: false,
  },
  {
    id: 'partCount',
    enabled: true,
  },
  {
    id: 'utilization',
    enabled: true,
  },
  {
    id: 'downtime',
    enabled: true,
  },
  {
    id: 'alarms',
    enabled: true,
  },
  {
    id: 'partsGoal',
    enabled: true,
  },
  {
    id: 'cycleTime',
    enabled: true,
  },
  {
    id: 'oee',
    enabled: true,
  },
];

export const FLAG_KEYS = {
  SHIFT_RUNS_ENABLED: 'shiftRunsEnabled',
  LABOR_TICKET_ENABLED: 'laborTicketEnabled',
  SCHEDULED_TIME_ENABLED: 'scheduledTimeEnabled',
  WORK_ORDER_FLOW_ENABLED: 'workOrderFlowEnabled',
  PRODUCTION_SCHEDULE_ENABLED: 'productionScheduleEnabled',
  STOP_INCOMPLETE_JOB_AND_SIGN_OUT_ENABLED:
    'stopIncompleteJobAndSignOutEnabled',
};

export const LABOR_TICKET_ACTIONS = {
  STOP_RUN: 'stopRun',
  OPERATOR_LOGOUT: 'operatorLogout',
  OPERATOR_LOGIN: 'operatorLogin',
  CHANGE_ACTIVITIES: 'changeActivities',
  // TODO: these 2 event names should be renamed to something more meaningful
  STOP_AND_CREATE: 'erpJobControlBarStopRun',
  STOP_AND_CREATE_OPERATOR_LOGOUT: 'erpJobControlBarOperatorLogout',
};

export {
  JOB_STATUSES,
  MACHINE_STATUSES,
  POLL_INTERVAL,
  HEAVY_UPDATE_INTERVAL,
  LIGHT_UPDATE_INTERVAL,
  THIRTY_SECOND_INTERVAL,
  SIXTY_SECOND_INTERVAL,
  LONG_SCOPE_HOURS,
  NETWORK_ERROR_RETRY_TIMEOUT,
  ERROR_KEYS,
  ACTIVITY_MODES,
  TEMP_REFS,
  CONTROL_ACTIONS,
  MAX_QUERY_BOUNDARY_HOURS,
  DEFAULT_TABS,
  LAB_REFRESH_DURATION,
};
