import React from 'react';
import { useTranslation } from 'react-i18next';
import { ControlBarButton } from '@m12s/component-library';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FLAG_KEYS } from 'lib/constants';

import { UtilityMenu } from 'components/UtilityMenu';
import { ban } from 'lib/icons';

import Routes from 'lib/utils/routes';

import { getIsAPMEnabled } from 'lib/selectors/getIsAPMEnabled';
import {
  ControlBarResponsive,
  MobileIcon,
  ResponsiveText,
  Scrollable,
} from './styled';
import { ActivitiesJobControlBar } from './ActivitiesJobControlBar';
import { APMControlBar } from './APMControlBar';
import { CategorizeDowntime } from './ERPJobControlBar/CategorizeDowntime';

const DefaultJobControlBar = ({
  setControlAction,
  history,
  machine,
  hasOpenJobRun,
  hasOpenActivitySet,
  isPaused,
  hasOpenSetupActivity,
}) => {
  const { t } = useTranslation();
  const flags = useFlags();
  const laborTicketsEnabled = flags[FLAG_KEYS.LABOR_TICKET_ENABLED];

  const isAPMEnabled = useSelector(getIsAPMEnabled);

  const JobControlBar = isAPMEnabled ? APMControlBar : ActivitiesJobControlBar;

  return (
    <ControlBarResponsive id="job-control-bar">
      <UtilityMenu />
      <Scrollable>
        <JobControlBar
          setControlAction={setControlAction}
          history={history}
          machine={machine}
          hasOpenJobRun={hasOpenJobRun}
          hasOpenActivitySet={hasOpenActivitySet}
          isPaused={isPaused}
          hasOpenSetupActivity={hasOpenSetupActivity}
        />
        {!laborTicketsEnabled && (
          <ControlBarButton
            id="reject-parts"
            onClick={() => {
              return history.push(Routes.machineIdRejectPartsPath(machine.id));
            }}
          >
            <MobileIcon icon={ban} />
            <ResponsiveText
              full={t('rejectParts|full')}
              medium={t('rejectParts|medium')}
              short={t('rejectParts|short')}
            />
          </ControlBarButton>
        )}
        <CategorizeDowntime
          history={history}
          machine={machine}
          text="categorize"
        />
      </Scrollable>
    </ControlBarResponsive>
  );
};

export { DefaultJobControlBar };
