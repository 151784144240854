import React from 'react';
import { useTranslation } from 'react-i18next';
import { ControlBarButton } from '@m12s/component-library';
import { sync } from 'lib/icons';
import Routes from 'lib/utils/routes';
import TokenStore from 'lib/api/links/TokenStore';
import { MobileIcon, ResponsiveText } from '../styled';

const SwitchMachine = ({ history }) => {
  const { t } = useTranslation();
  const presetMachineToken = new TokenStore('presetMachine');
  return (
    <ControlBarButton
      id="job-control-switch-machine"
      onClick={() => {
        presetMachineToken.clear();
        return history.push(Routes.switchMachinesPath());
      }}
    >
      <MobileIcon icon={sync} />
      <ResponsiveText
        full={t('changeMachine|full')}
        medium={t('changeMachine|medium')}
        short={t('changeMachine|short')}
      />
    </ControlBarButton>
  );
};

export { SwitchMachine };
