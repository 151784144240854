import React from 'react';
import PropTypes from 'prop-types';
import { palette } from '@m12s/component-library';
import LaborTicketBaseActionModal from 'components/LaborTicket/LaborTicketBaseActionModal';
import { useTranslation } from 'react-i18next';

import { ModalButton } from './styled';

// TODO: missing translations
const LaborTicketStopOrLeaveModal = ({
  isSetup = false,
  isOpen,
  handleClose,
  handleStopJob,
  handleLeaveOpen,
  handleStopJobAndSignOut,
  enableStopJobAndSignOut,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {isOpen && (
        <LaborTicketBaseActionModal
          isOpen={isOpen}
          onClose={handleClose}
          header={t(
            isSetup
              ? 'Leave the current setup open?'
              : 'Are you still working on this job?'
          )}
          details={
            <div>
              {isSetup
                ? t(`Please confirm whether you would leave the current
              setup running on this machine in the MachineMetrics system, or if
              you would like to stop the job upon sign out (paused setup time
              will not be counted against expected).`)
                : t(`Please confirm whether you would like to submit this labor ticket 
              and continue working on this job, or if you would like to stop submit 
              the labor ticket and stop the current job.`)}
            </div>
          }
          buttons={[
            enableStopJobAndSignOut ? (
              <ModalButton
                key="sumbit-and-sign"
                variant="inferior"
                onClick={() => {
                  return handleStopJobAndSignOut();
                }}
              >
                {t('Submit Work and Sign Out')}
              </ModalButton>
            ) : (
              <ModalButton
                key="stop job"
                variant="inferior"
                onClick={() => {
                  return handleStopJob();
                }}
              >
                {t('Stop Job')}
              </ModalButton>
            ),
            <ModalButton
              key="leave-or-continue"
              customStyles={{
                backgroundColor: palette.Indigo600,
              }}
              hoverStyles={{
                backgroundColor: palette.Indigo700,
              }}
              onClick={() => {
                return handleLeaveOpen();
              }}
            >
              {t(isSetup ? 'Leave Open' : 'Continue Working')}
            </ModalButton>,
          ]}
        />
      )}
    </>
  );
};

LaborTicketStopOrLeaveModal.propTypes = {
  isOpen: PropTypes.bool,
  handleStopJob: PropTypes.func,
  handleLeaveOpen: PropTypes.func,
  handleClose: PropTypes.func,
  isSetup: PropTypes.bool,
  handleStopJobAndSignOut: PropTypes.func,
  enableStopJobAndSignOut: PropTypes.bool,
};

export default LaborTicketStopOrLeaveModal;
