import React, { useState } from 'react';
import { Route, useHistory, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import MainLayout from 'components/Layouts/MainLayout';
import { MachineHeader } from 'components/MachineHeader';
import { LanguageModal } from 'components/LanguageModal';
import { getHasOpenJobRun } from 'lib/selectors/getHasOpenJobRun';

import { getIsPaused } from 'lib/selectors/getIsPaused';
import { getHasOpenActivitySet } from 'lib/selectors/getHasOpenActivitySet';
import { getDisconnectedErrorStatus } from 'lib/selectors/getDisconnectedErrorStatus';
import Routes from 'lib/utils/routes';
import { getMachine } from 'lib/selectors/getMachine';
import { getOpenTraining } from 'lib/selectors/getOpenTraining';
import { getHasOpenSetupActivity } from 'lib/selectors/getHasOpenSetupActivity';

import { DowntimePopup } from 'components/DowntimePopup';
import { WorkflowPopup } from 'components/WorkflowPopup';
import { MessagePopup } from 'components/MessagePopup';
import { TrainingDoc } from 'components/TrainingDoc';
import { getHomeTab } from 'lib/selectors/getTabs';
import { actionCloseLanguageModal } from 'lib/actions';
import { getLanguageModalOpen } from 'lib/selectors/getLanguageModalOpen';
import { useIsERPJobControlBarFlow } from 'lib/hooks/useIsERPJobControlBarFlow';
import MachineMain from './MachineMain';
import DisconnectedErrorPage from './DisconnectedErrorPage';
import { ControlActionModal } from './ControlActionModal';
import { ERPJobControlBar } from './ERPJobControlBar';
import { DefaultJobControlBar } from './DefaultJobControlBar';

const Page = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const showERPJobControlBar = useIsERPJobControlBarFlow();

  const openTraining = useSelector(getOpenTraining);
  const machine = useSelector(getMachine);
  const hasOpenJobRun = useSelector(getHasOpenJobRun);
  const hasOpenActivitySet = useSelector(getHasOpenActivitySet);
  const hasOpenSetupActivity = useSelector(getHasOpenSetupActivity);
  const disconnectedErrorStatus = useSelector(getDisconnectedErrorStatus);
  const homeTab = useSelector(getHomeTab);
  const isPaused = useSelector(getIsPaused);

  const JobControlBar = showERPJobControlBar
    ? ERPJobControlBar
    : DefaultJobControlBar;

  const [controlAction, setControlAction] = useState(null);

  const languageModalOpen = useSelector(getLanguageModalOpen);

  if (openTraining) {
    return <TrainingDoc />;
  }

  return (
    <>
      <Route exact path={Routes.machineIdHomePath.pattern}>
        <Redirect to={Routes.machineIdHomeTabPath(machine.id, homeTab)} />
      </Route>
      <Route
        path={Routes.machineIdHomeTabPath.pattern}
        render={({
          match: {
            params: { tab },
          },
        }) => {
          return (
            <>
              <WorkflowPopup />
              <DowntimePopup />
              <MessagePopup />
              {controlAction && (
                <ControlActionModal
                  controlAction={controlAction}
                  setControlAction={setControlAction}
                />
              )}
              <LanguageModal
                isOpen={languageModalOpen}
                onClose={() => {
                  return dispatch(actionCloseLanguageModal());
                }}
              />
              <MainLayout.Container direction="column">
                <MachineHeader />
                <MainLayout.Container direction="column">
                  {disconnectedErrorStatus ? (
                    <DisconnectedErrorPage
                      errorStatus={disconnectedErrorStatus}
                    />
                  ) : (
                    <MachineMain tab={tab} />
                  )}
                </MainLayout.Container>
                <JobControlBar
                  setControlAction={setControlAction}
                  history={history}
                  machine={machine}
                  hasOpenJobRun={hasOpenJobRun}
                  hasOpenActivitySet={hasOpenActivitySet}
                  isPaused={isPaused}
                  hasOpenSetupActivity={hasOpenSetupActivity}
                />
              </MainLayout.Container>
            </>
          );
        }}
      />
    </>
  );
};

export default Page;
