import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { palette, Icon, CardGrid, Card } from '@m12s/component-library';
import Downshift from 'downshift';
import { chevronRight, questionCircle } from 'lib/icons';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import _startCase from 'lodash/startCase';
import Routes from 'lib/utils/routes';

import { STOP_OPERATOR } from 'lib/api/mutations';
import { getCurrentOperator } from 'lib/selectors/getCurrentOperator';
import {
  getActiveOperators,
  getHasUnmappedActiveOperators,
} from 'lib/selectors/getActiveOperators';
import { getMachine } from 'lib/selectors/getMachine';
import {
  actionOpenLaborTicketModal,
  actionSetRecentOperatorRuns,
} from 'lib/actions';
import { LABOR_TICKET_ACTIONS } from 'lib/constants';
import { getCanCloseLaborTicket } from 'lib/selectors/getCanOpenLaborTicket';
import { useIsLaborTicketFlow } from 'lib/hooks/useIsLaborTicketFlow';

import { AppNav } from 'components/AppNav';
import { H5 } from 'components/Text';
import { SignInModal } from 'components/SignInModal';
import { SignOutModal } from 'components/SignOutModal';
import { SearchInput } from 'components/SearchInput';
import { EmptyWithGraphic } from 'components/Empty/EmptyWithGraphic';
import NoOperators from 'graphics/NoOperators';
import { InlineAlert } from 'components/InlineAlert/InlineAlert';

import { Root, GridWrapper, InputWrapper } from './styled';
import { operatorUnlistedOnERPCopy } from './copy';

function stateReducer(state, changes) {
  switch (changes.type) {
    case Downshift.stateChangeTypes.blurInput:
    case Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem:
    case Downshift.stateChangeTypes.mouseUp:
    case Downshift.stateChangeTypes.touchEnd:
    case Downshift.stateChangeTypes.clickItem:
      return state;
    default:
      return changes;
  }
}

const selectedInitialValue = { operator: null, confirmed: false };

const UserSelect = ({ onSubmit }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const currentOperator = useSelector(getCurrentOperator);
  const laborTicketEnabled = useIsLaborTicketFlow();
  const canCloseLaborTicket = useSelector(getCanCloseLaborTicket);

  const operators = useSelector(getActiveOperators);
  const hasUnmappedActiveOperators = useSelector(getHasUnmappedActiveOperators);
  const machine = useSelector(getMachine);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(selectedInitialValue);

  const [stopOperator] = useMutation(STOP_OPERATOR, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ stopOperatorResponse }) => {
      if (stopOperatorResponse) {
        const {
          machine: localMachine,
          operator,
          ...operatorRun
        } = stopOperatorResponse;
        const mappedPayload = {
          operatorRuns: [
            {
              ...operatorRun,
              machineId: localMachine?.id,
              operatorId: operator?.id,
            },
          ],
        };
        dispatch(actionSetRecentOperatorRuns(mappedPayload));
      }
      history.goBack();
    },
  });

  const unconfirmed =
    !!currentOperator && !!selected.operator && !selected.confirmed;
  const confirmed =
    !!selected.operator && (!currentOperator || selected.confirmed);

  const logoutSubmit = useCallback(() => {
    stopOperator({
      variables: {
        input: {
          machineId: machine.id,
        },
      },
    });
  }, [machine, stopOperator]);

  return (
    <Root id="user-select-flow">
      {unconfirmed && (
        <SignOutModal
          operator={selected.operator}
          currentOperator={currentOperator}
          onYes={() => {
            return setSelected({ ...selected, confirmed: true });
          }}
          onNo={() => {
            return setSelected(selectedInitialValue);
          }}
          onClose={() => {
            return setSelected(selectedInitialValue);
          }}
        />
      )}
      {confirmed && (
        <SignInModal
          currentOperator={currentOperator}
          operator={selected.operator}
          onSubmit={(...args) => {
            return onSubmit(...args) && setSelected(selectedInitialValue);
          }}
          onClose={() => {
            return setSelected(selectedInitialValue);
          }}
        />
      )}
      <AppNav
        onClose={() => {
          return history.goBack();
        }}
      >
        <H5 color={palette.Yellow400} bold>
          {selected.operator
            ? selected.operator.name
            : currentOperator
            ? currentOperator.name
            : t('No Operator')}
        </H5>
      </AppNav>
      <Downshift
        isOpen
        selectedItem={selected.operator}
        stateReducer={stateReducer}
        itemToString={(item) => {
          return item ? item.name : '';
        }}
      >
        {({ getInputProps, getMenuProps, inputValue, getRootProps }) => {
          const filteredOperators = operators.filter((item) => {
            const isCurrentOperator = !!(
              currentOperator && item?.id === currentOperator?.id
            );
            if (isCurrentOperator) {
              // exclude active operator from card list
              return false;
            }
            return item.name.toLowerCase().includes(inputValue.toLowerCase());
          });
          const hasOperatorProfiles = operators.length > 0;

          return (
            <>
              <InputWrapper hidden={operators.length === 0}>
                <SearchInput
                  rootProps={getRootProps({}, { suppressRefError: true })}
                  inputProps={getInputProps({
                    onBlur: (fn) => {
                      return fn;
                    },
                  })}
                />
              </InputWrapper>
              {hasUnmappedActiveOperators && (
                <InlineAlert text={t(operatorUnlistedOnERPCopy)} />
              )}
              {hasOperatorProfiles ? (
                <GridWrapper>
                  <CardGrid {...getMenuProps()}>
                    {currentOperator && (
                      <Card
                        color={null}
                        key="signout"
                        type="pill"
                        icon={<Icon icon={chevronRight} />}
                        onClick={() => {
                          if (laborTicketEnabled && canCloseLaborTicket) {
                            history.push(Routes.machineIdHomePath(machine.id));
                            return dispatch(
                              actionOpenLaborTicketModal(
                                LABOR_TICKET_ACTIONS.OPERATOR_LOGOUT
                              )
                            );
                          }
                          return logoutSubmit();
                        }}
                      >
                        {_startCase(t(`sign out`))}
                      </Card>
                    )}
                    {filteredOperators.map((item) => {
                      return (
                        <Card
                          color={null}
                          key={item.id}
                          type="pill"
                          icon={<Icon icon={chevronRight} />}
                          onClick={() => {
                            return setSelected({
                              operator: item,
                              confirmed: false,
                            });
                          }}
                        >
                          {item.name}
                        </Card>
                      );
                    })}
                  </CardGrid>
                </GridWrapper>
              ) : (
                <EmptyWithGraphic
                  icon={hasOperatorProfiles && questionCircle}
                  text={
                    hasOperatorProfiles
                      ? t('No Results Found')
                      : t('No Operators')
                  }
                  subtext={
                    hasOperatorProfiles
                      ? t('noOperatorFound')
                      : t('noOperatorResult')
                  }
                  Graphic={
                    !hasOperatorProfiles && <NoOperators margin="1rem" />
                  }
                />
              )}
            </>
          );
        }}
      </Downshift>
    </Root>
  );
};

export { UserSelect };
