import styled from 'styled-components';
import { palette, Select } from '@m12s/component-library';

export const InputsContainer = styled.div`
  > {
    :not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

export const InputsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
`;

export const InputContainer = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: ${palette.Grey700};
`;

export const Input = styled.input`
  border: none;
  width: 100%;
  height: 2.5rem;
  padding: 0.75rem 0.625rem;
  color: ${palette.Grey700};
  font-size: 0.875rem;

  &:hover {
    background: ${palette.Grey100};
  }
  &:focus-visible {
    outline: ${palette.Grey500} auto 2px;
  }
`;

/* NumberInput styles */
export const NumberInputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  border-radius: 0.25rem;
  border: 1px solid ${palette.Grey400};

  > {
    :first-child {
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }
    :not(:last-child) {
      border-right: 1px solid ${palette.Grey400};
    }
    :last-child {
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }
  }
`;
export const AdjustmentButton = styled.button`
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  background: ${palette.White};

  &:hover {
    background: ${palette.Grey100};
  }
  &:active {
    outline: ${palette.Grey500} auto 2px;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    outline: none;
  }
`;
/* ~ NumberInput styles */

/* Select styles */
export const SelectInput = styled(Select)`
  font-size: 0.875rem;
  color: ${palette.Grey700};
  transition: all 0.2s ease;
`;
/* ~ Select styles */
