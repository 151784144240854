/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TextArea, palette } from '@m12s/component-library';
import { useQuery, gql } from '@apollo/client';
import { useNetworkError } from 'lib/hooks';

import { getPartAdjustmentTypes } from 'lib/selectors/getPartAdjustmentTypes';

import { getHasOpenSetupActivity } from 'lib/selectors/getHasOpenSetupActivity';
import { InputsContainer, InputsRow, SelectInput } from './Form/styled';
import NumberInput from './Form/NumberInput';
import StatusInput from './Form/StatusInput';

const LABOR_TICKET_REJECT_REASONS = gql`
  query queryLaborTicketRejectReasons($partAdjustmentIds: [Int!]) {
    erpReasons(where: { rejectReasonRef: { _in: $partAdjustmentIds } }) {
      annotationTypeRef
      category
      code
      companyId
      description
      reasonId
      rejectReasonRef
      entityType
      reasonRef
    }
  }
`;

const TextAreaContainer = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  color: ${palette.Grey700};
  font-size: 0.875rem;
  font-weight: 500;
`;

const formatReasonOption = (reason) => {
  return {
    label: reason.code || reason.description || reason.reasonId,
    value: { reasonRef: reason.rejectReasonRef, reasonId: reason.reasonId },
  };
};

const LaborTicketForm = ({ form, handleFormChange, loading }) => {
  const selectedRejectReason = form.rejectReason.value;
  const rejectedParts = form.rejectedParts.value;
  const partAdjustmentTypes = useSelector(getPartAdjustmentTypes);
  const hasOpenSetupActivity = useSelector(getHasOpenSetupActivity);

  const partAdjustmentIds = partAdjustmentTypes?.map((type) => {
    return type.id;
  });

  const requestERPRejectReasons = useQuery(LABOR_TICKET_REJECT_REASONS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      partAdjustmentIds,
    },
    skip: !partAdjustmentIds?.length,
  });

  useNetworkError(requestERPRejectReasons, 'erp reject reasons');

  const rejectReasons = requestERPRejectReasons.data?.erpReasons;
  const rejectOptions = rejectReasons?.map((reason) => {
    return formatReasonOption(reason);
  });

  return (
    <div>
      <InputsContainer>
        <InputsRow>
          <NumberInput
            label="Total Parts"
            path="totalParts"
            disabled={loading}
            form={form}
            handleFormChange={(path, value) => {
              if (rejectedParts > value) {
                if (rejectedParts === 1) {
                  handleFormChange('rejectReason', null);
                }
                handleFormChange('rejectedParts', form.rejectedParts.value - 1);
              }
              return handleFormChange(path, value);
            }}
          />
          <NumberInput
            label="Rejected Parts"
            path="rejectedParts"
            disabled={loading || !rejectOptions?.length}
            form={form}
            handleFormChange={(path, value) => {
              if (!value) {
                handleFormChange('rejectReason', null);
              }

              if (value > 0) {
                handleFormChange('workOrderComplete', false);
              }

              return handleFormChange(path, value);
            }}
            max={form.totalParts.value}
          />
        </InputsRow>
        {rejectedParts > 0 && (
          <SelectInput
            label="ERP Reject Reason"
            value={
              selectedRejectReason
                ? formatReasonOption(selectedRejectReason)
                : null
            }
            onChange={(option) => {
              const updatedReason = rejectReasons.find((reason) => {
                return reason.reasonId === option.value.reasonId;
              });
              return (
                !loading && handleFormChange('rejectReason', updatedReason)
              );
            }}
            options={rejectOptions}
            placeholder="Please select one"
            labelStyles={{
              fontSize: '0.875rem',
              fontWeight: 500,
            }}
            required={!!rejectedParts}
            isDisabled={!rejectedParts || !rejectOptions?.length}
          />
        )}

        <StatusInput
          hasOpenSetupActivity={hasOpenSetupActivity}
          form={form}
          handleFormChange={handleFormChange}
          loading={loading}
        />

        <TextAreaContainer>
          <label htmlFor="comment">Additional Comments</label>
          <TextArea
            disabled={loading}
            id="comment"
            name="comment"
            rows="8"
            expand
            placeholder="Let us know anything that might be helpful"
            onChange={(event) => {
              return handleFormChange('comment', event.target.value);
            }}
          />
        </TextAreaContainer>
      </InputsContainer>
    </div>
  );
};

LaborTicketForm.propTypes = {
  form: PropTypes.object,
  handleFormChange: PropTypes.func,
  loading: PropTypes.bool,
};

export default LaborTicketForm;
