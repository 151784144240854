import { useSelector } from 'react-redux';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { palette } from '@m12s/component-library';
import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FLAG_KEYS } from 'lib/constants';
import { useForceLoginOnSubmit } from 'lib/hooks/useForceLoginOnSubmit';
import Routes from 'lib/utils/routes';

// Components
import { AppNav } from 'components/AppNav';
import MainLayout from 'components/Layouts/MainLayout';
import { AddWorkOrderModal } from 'components/JobTable/AddWorkOrderModal';
import { ForceLogin } from 'components/ForceLogin';

// Selectors
import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';
import { getMachine } from 'lib/selectors/getMachine';
import { getNeedsForceLogin } from 'lib/selectors/getCanOpenLaborTicket';
import WorkOrderCard from './WorkOrderCard/index';

// misc
import { searchOperations } from './helpers';
import useSelectOperationFlow from './useSelectOperationFlow';

const WorkOrderContainer = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, 18.625rem);
  justify-content: center;
  background-color: ${palette.Grey100};
  padding: 1rem 1rem 2rem 1rem;
  height: fit-content;
  @media (max-width: 660px) {
    grid-template-columns: 1fr;
  }
`;

const ControlsContainer = styled.section`
  border-top: solid 1px ${palette.Grey400};
  border-bottom: solid 1px ${palette.Grey300};
  padding: 1rem;
  display: flex;
  white-space: nowrap;
  align-items: center;
  gap: 1rem;
`;

const SelectWorkOrderOp = ({
  handleOnClose = () => {},
  nextStep,
  dispatch,
}) => {
  const flags = useFlags();
  const productionScheduleEnabled =
    flags[FLAG_KEYS.PRODUCTION_SCHEDULE_ENABLED];

  const [manualModalOpen, setManualModalOpen] = useState(false);
  const [newRunModalOpen, setNewRunModalOpen] = useState(false);

  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);
  const machine = useSelector(getMachine);
  const needsForceLogin = useSelector(getNeedsForceLogin);

  const {
    operations,
    pageLoading,
    excludeOperations,
    searchAll,
    search,
    error,
    schedule,
    Components,
  } = useSelectOperationFlow({
    setManualModalOpen,
    setNewRunModalOpen,
  });

  const {
    LoadMoreButton,
    Checkbox,
    SearchInput,
    EmptyContent,
    Header,
  } = Components;

  const filteredOperations = useMemo(() => {
    if (!searchAll) {
      return searchOperations(search, operations);
    }
    return operations;
  }, [search, operations, searchAll]);

  const showEmptyMessage = error || pageLoading || !filteredOperations?.length;

  const redirectPath = Routes.machineIdSelectJobPath(machine.id);
  const onSubmit = useForceLoginOnSubmit({
    redirectPath,
  });
  if (needsForceLogin) {
    return <ForceLogin onSubmit={onSubmit} />;
  }

  return (
    <MainLayout.Container
      direction="column"
      overlay
      id="select-work-order-op"
      style={{ backgroundColor: palette.Grey100 }}
    >
      <AppNav
        iconColor={palette.Grey700}
        color={palette.White}
        onClose={handleOnClose}
      >
        {Header}
      </AppNav>
      <ControlsContainer>
        {manualModalOpen && (
          <AddWorkOrderModal
            forEditWorkOrderId
            handleOnClose={() => {
              return setManualModalOpen(false);
            }}
          />
        )}
        {newRunModalOpen && (
          <AddWorkOrderModal
            isStopStartNewRun
            handleOnClose={() => {
              setNewRunModalOpen(false);
            }}
          />
        )}
        {SearchInput}
        {Checkbox}
      </ControlsContainer>
      {showEmptyMessage ? (
        EmptyContent
      ) : (
        <div
          style={{
            overflow: 'auto',
            display: 'grid',
            backgroundColor: palette.Grey100,
          }}
        >
          <WorkOrderContainer>
            {currentWorkOrderOp && (
              <WorkOrderCard
                active
                workOrderOp={currentWorkOrderOp}
                nextStep={nextStep}
                dispatch={dispatch}
                key={`${currentWorkOrderOp.workOrderId}-${currentWorkOrderOp.partNumber}-${currentWorkOrderOp.sequenceNumber}`}
              />
            )}
            {filteredOperations.map((workOrderOp) => {
              const { workOrder } = workOrderOp;
              const blockingOperation =
                productionScheduleEnabled &&
                schedule?.find((operation) => {
                  return (
                    operation.workOrderId === workOrderOp.workOrderId &&
                    operation.sequenceNumber < workOrderOp.sequenceNumber
                  );
                });

              const excludeOperation = !!excludeOperations?.find(
                (workOrderOperationRef) => {
                  return (
                    String(workOrderOp.workOrderOperationRef) ===
                    String(workOrderOperationRef)
                  );
                }
              );

              return (
                <WorkOrderCard
                  isBlockedBySequence={!!blockingOperation}
                  workOrderOp={workOrderOp}
                  exclude={excludeOperation}
                  nextStep={nextStep}
                  dispatch={dispatch}
                  key={`${workOrder.workOrderId}-${workOrder.partNumber}-${workOrderOp.sequenceNumber}`}
                />
              );
            })}
          </WorkOrderContainer>
          {LoadMoreButton}
        </div>
      )}
    </MainLayout.Container>
  );
};

SelectWorkOrderOp.propTypes = {
  handleOnClose: PropTypes.func,
  nextStep: PropTypes.func,
  dispatch: PropTypes.func,
};

export default SelectWorkOrderOp;
