import React from 'react';
import { useTranslation } from 'react-i18next';
import { ControlBarButton } from '@m12s/component-library';
import { check, playRegular, signOut } from 'lib/icons';
import { UtilityMenu } from 'components/UtilityMenu';
import { useDispatch, useSelector } from 'react-redux';
import Routes from 'lib/utils/routes';
import { getCurrentOperator } from 'lib/selectors/getCurrentOperator';
import { CONTROL_ACTIONS, LABOR_TICKET_ACTIONS } from 'lib/constants';
import { actionOpenLaborTicketModal } from 'lib/actions';
import { getCompany } from 'lib/selectors/getCompany';
import {
  ControlBarResponsive,
  MobileIcon,
  ResponsiveText,
  Scrollable,
} from './styled';
import { CategorizeDowntime } from './ERPJobControlBar/CategorizeDowntime';
import { SignInAndSelectJob } from './ERPJobControlBar/SignInAndSelectJob';

const ERPJobControlBar = ({
  setControlAction,
  history,
  machine,
  hasOpenActivitySet,
  isPaused,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const operator = useSelector(getCurrentOperator);

  // const hasActivityToSwitchTo = useSelector(getHasActivityToSwitchTo);
  // const hasThreeOrMoreActivities = useSelector(getHasThreeOrMoreActivities);
  // {hasActivityToSwitchTo && hasThreeOrMoreActivities && (
  // )}

  const company = useSelector(getCompany);
  const enableMachineSwitching = company?.machineSwitching === true;

  if (!operator || !hasOpenActivitySet) {
    return (
      <SignInAndSelectJob
        history={history}
        machine={machine}
        operator={operator}
        hasOpenActivitySet={hasOpenActivitySet}
        enableMachineSwitching={enableMachineSwitching}
      />
    );
  }

  return (
    <ControlBarResponsive id="job-control-bar">
      <UtilityMenu />
      <Scrollable>
        {isPaused ? (
          <ControlBarButton
            id="job-control-resume-job"
            onClick={() => {
              return setControlAction(CONTROL_ACTIONS.RESUME);
            }}
          >
            <MobileIcon icon={playRegular} />
            <ResponsiveText
              full={t('resumeJob|full')}
              medium={t('resumeJob|medium')}
              short={t('resumeJob|short')}
            />
          </ControlBarButton>
        ) : (
          <ControlBarButton
            id="job-control-bar-submit"
            onClick={() => {
              return dispatch(
                actionOpenLaborTicketModal(LABOR_TICKET_ACTIONS.STOP_AND_CREATE)
              );
            }}
          >
            <MobileIcon icon={check} />
            <ResponsiveText
              full={t('submitWork|full')}
              medium={t('submitWork|medium')}
              short={t('submitWork|short')}
            />
          </ControlBarButton>
        )}
        <CategorizeDowntime history={history} machine={machine} />

        <ControlBarButton
          id="signout"
          onClick={() => {
            history.push(Routes.machineIdHomePath(machine.id));
            return dispatch(
              actionOpenLaborTicketModal(
                LABOR_TICKET_ACTIONS.STOP_AND_CREATE_OPERATOR_LOGOUT
              )
            );
          }}
        >
          <MobileIcon icon={signOut} />
          <ResponsiveText
            full={t('signout|full')}
            medium={t('signout|medium')}
            short={t('signout|short')}
          />
        </ControlBarButton>
      </Scrollable>
    </ControlBarResponsive>
  );
};

export { ERPJobControlBar };
