/* eslint-disable no-case-declarations */
/* eslint-disable no-fallthrough */
/* eslint-disable no-else-return */
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';
import _reject from 'lodash/reject';
import _concat from 'lodash/concat';
import _dropWhile from 'lodash/dropWhile';
import _without from 'lodash/without';
import _omit from 'lodash/omit';
import _omitBy from 'lodash/omitBy';
import _sumBy from 'lodash/sumBy';
import {
  setRecentActivitySetsData,
  getOrderedActivityRefs,
  getOrderedActivitySetRefs,
  getOrderedJobIds,
  getSortedUniqueAnnotations,
} from 'lib/reducers/utils';
import {
  startOfAppHistory,
  toISO,
  millis,
  now,
  isBefore,
} from 'lib/utils/date';
import {
  createStatusIntervalsFromExecution,
  joinIntervalSets,
  addExtendedDowntime,
} from 'lib/utils/intervals';

import {
  actionSetMachine,
  actionSetShifts,
  actionSetCompany,
  actionSetUser,
  actionSetAnnotations,
  actionSetLoading,
  actionUnsetLoading,
  actionRecalculate,
  actionSetJobRun,
  actionSetJobTotal,
  actionSetOperators,
  actionSetAlarms,
  actionSetShiftStatusIntervals,
  actionSetDowntimeIntervals,
  actionSetScopeStatusIntervals,
  actionSetPartCountEvents,
  actionSetPartAdjustmentTypes,
  actionSetAnnotationTypes,
  actionSetInitialData,
  actionSetPopupStack,
  actionClosePopup,
  actionSetNetworkError,
  actionUnsetNetworkError,
  actionRetryNetworkError,
  actionSetPartAdjustments,
  actionCreatePartAdjustmentSuccess,
  actionReset,
  actionDismissPopup,
  actionClearDowntimeFlyup,
  actionTriggerDowntimeFlyup,
  actionDismissDowntimeFlyup,
  actionUpdateConnectionStatus,
  actionSetLatestHeartbeat,
  actionSetLastRefresh,
  actionToggleTraining,
  actionSubmitAnnotation,
  actionSetRecentActivitySets,
  actionSetRecentOperatorRuns,
  actionSetActivityTypes,
  actionSetActivitiesStatus,
  actionSubmitAnnotationFailure,
  actionSubmitAnnotationSuccess,
  actionSetProgramNameData,
  actionSetPartCountBuckets,
  actionSetFirstPart,
  actionOptimisticActivitySetOpen,
  actionCanonicalActivitySetOpen,
  actionCanonicalActivitySetDelete,
  actionOptimisticActivitySetClose,
  actionCanonicalActivitySetClose,
  actionCanonicalActivitySetReopen,
  actionOptimisticActivitySetUpdate,
  actionCanonicalActivitySetUpdate,
  actionOptimisticActivityCreate,
  actionCanonicalActivityCreate,
  actionOptimisticActivityUpdate,
  actionCanonicalActivityUpdate,
  actionCanonicalActivityDelete,
  actionSetLastPartTimestamp,
  actionSetWorkflows,
  actionTriggerWorkflow,
  actionOptimisticActivitySwitch,
  actionCanonicalActivitySwitch,
  actionCanonicalActivityUnswitch,
  actionSetCustomTabURL,
  actionSetTabs,
  actionSwitchMachine,
  actionSetLatestPingTime,
  actionSetSetupRawParts,
  actionSetShiftSetupRawParts,
  actionSetLocationRef,
  actionSetHealthData,
  actionDismissUpdatePopup,
  actionOpenLanguageModal,
  actionCloseLanguageModal,
  actionSetLabsAuthorized,
  actionSetUtilizationHourlies,
  actionSetAgentStatus,
  actionOpenLaborTicketModal,
  actionSetPendingActivityTypeRef,
  actionCloseLaborTicketModal,
  actionSetLaborTicketAction,
  actionSetMachineGroups,
  actionSetLaborTickets,
  actionSetMachineERPResources,
  actionSetCurrentWorkOrderOp,
  actionSetCurrentWorkOrderOpGoodParts,
  actionSetLaborTicketActionLoading,
  actionSetWorkOrderOpActionLoading,
  actionSetLaborTicketScopeAdjustment,
  actionSetDefaultManualEntry,
  actionSetScheduledTime,
  actionSetScheduledTimeEnabled,
  actionSetLaunchDarkleyFlags,
} from 'lib/actions';

export const unorderedInitialState = {
  launchDarklyFlags: {},
  activities: {
    byRefs: {},
    allRefs: [],
  },
  activitySets: {
    byRefs: {},
    allRefs: [],
  },
  activitiesStatusSubscription: { loading: true, data: null },
  activityTypes: { loading: true, data: [] },
  agentStatus: { connected: true, successiveFailures: 0 },
  alarms: [],
  annotationTypes: [],
  annotations: { loading: true, data: [] },
  company: { loading: true },
  connection: {
    status: null,
    disconnectedAt: null,
  },
  customTabs: [],
  downtimeStartDate: null,
  executionIntervals: [],
  utilizationHourlies: {
    hourlies: [],
    generatedAt: null,
  },
  firstPartTimestamp: null,
  downtimeFlyup: { triggered: false, dismissed: false },
  healthData: [],
  initialDataSet: false,
  jobs: {
    byId: {},
    allIds: [],
  },
  jobRun: { loading: true, data: {} },
  languageModalOpen: false,
  workOrderManagement: {
    currentLaborTicket: null,
    laborTickets: [],
    laborTicketScopeAdjustment: 0,
    laborTicketAction: null,
    laborTicketActionLoading: false,
    laborTicketModalOpen: false,
    currentWorkOrderOp: null,
    loadingCurrentWorkOrderOp: true,
    workOrderOpActionLoading: false,
    pendingActivityTypeRef: null,
    redirectPath: null,
    defaultManualEntry: '',
  },
  latestHeartbeat: null,
  latestPing: null,
  lastPartTimestamp: null,
  lastRefresh: null,
  loading: true,
  machine: {},
  machineGroups: [],
  machineSettings: {},
  networkErrors: {},
  openTraining: false,
  operatorRuns: [],
  operators: [],
  partAdjustments: [],
  partAdjustmentTypes: [],
  partCountEvents: [],
  partCountBuckets: {
    loading: true,
    queryArgs: { startDate: null },
    data: {
      scheduledPartBuckets: [],
      unscheduledPartBuckets: [],
    },
  },
  partKeys: null,
  programNameSubscription: { loading: true, data: {} },
  popupStack: [],
  recentActivitySetsPoll: {
    isInitialPollLoading: true,
    hasWriteLock: false,
  },
  recentOperatorRunsPoll: {
    isInitialPollLoading: true,
  },
  scheduledTime: {
    loading: true,
    isEnabled: false,
    data: {},
  },
  setupRawParts: 0,
  shiftSetupRawParts: 0,
  shifts: { loading: true, data: [] },
  statusIntervals: [],
  totalCycles: 0,
  totalRawParts: 0,
  totalScheduledRawParts: 0,
  totalUnscheduledRawParts: 0,
  tabs: { loading: true, data: [] },
  labsAuthorized: false,
  update: 0,
  user: { loading: true, data: {} },
  workflows: { loading: true, data: {}, lastTriggered: {} },
};

const orderedStateKeys = Object.keys(unorderedInitialState).sort();
// alphabetize state keys for easier visual inspection of redux store in dev tools
const initialState = orderedStateKeys.reduce((orderedObj, key) => {
  return {
    ...orderedObj,
    [key]: unorderedInitialState[key],
  };
}, {});

export default (state = initialState, action) => {
  const { type, ...params } = action;
  switch (type) {
    case actionReset.type:
      return { ...initialState, ...params };
    case actionSetLaunchDarkleyFlags.type:
      return { ...state, launchDarklyFlags: action.flags };
    case actionSwitchMachine.type:
      return {
        ...initialState,
        tabs: state.tabs,
        customTabs: [...state.customTabs],
        user: state.user,
        company: state.company,
        machine: params.machine,
        lastRefresh: state.lastRefresh,
      };
    case actionSetShifts.type:
      return {
        ...state,
        shifts: {
          loading: false,
          data: params.data || [],
        },
      };
    case actionSetCompany.type:
      return {
        ...state,
        company: {
          ...state.company,
          loading: params.loading,
          error: params.error,
          data: {
            ...state.company.data,
            ...params.data,
          },
        },
      };
    case actionSetLastRefresh.type:
      return {
        ...state,
        lastRefresh: now()
          .startOf('day') // for testing use 'minute' instead of 'day'
          .toISOString(),
      };
    case actionDismissUpdatePopup.type:
      return {
        ...state,
        popupStack: state.popupStack.filter((popup) => {
          return popup.popupType !== 'update';
        }),
      };
    case actionSetUser.type:
      return { ...state, user: { ...state.user, ...params } };
    case actionSetMachine.type:
      return { ...state, machine: params.machine };
    case actionSetAnnotations.type: {
      return {
        ...state,
        annotations: {
          loading: false,
          data: _uniqBy(
            _sortBy(
              (params.annotations || []).reduce((memo, annotation) => {
                return _concat(
                  _reject(memo, { id: annotation.id }),
                  annotation
                );
              }, state.annotations.data),
              'start'
            ),
            'start'
          ),
        },
      };
    }
    case actionSubmitAnnotation.type:
      return { ...state, annotations: { ...state.annotations, loading: true } };
    case actionSubmitAnnotationFailure.type:
      return {
        ...state,
        annotations: { ...state.annotations, loading: false },
      };
    case actionSubmitAnnotationSuccess.type: {
      return {
        ...state,
        annotations: {
          data: getSortedUniqueAnnotations(state, action.annotations),
          loading: false,
        },
      };
    }
    case actionSetJobTotal.type:
      return { ...state, jobTotal: params.jobTotal };
    case actionSetLoading.type:
      return { ...state, loading: true };
    case actionUnsetLoading.type:
      return { ...state, loading: false };
    case actionRecalculate.type:
      return { ...state, update: millis(now()) };
    case 'SET_TOKEN_SCOPE':
      return { ...state, tokenScope: params.scope };
    case 'SET_JOB_RUN':
    case actionSetJobRun.type:
      return { ...state, jobRun: { loading: false, data: params.jobRun } };
    case actionSetRecentActivitySets.type:
      // prevent background polling from (temporarily) overwriting any local mutations while server response pending
      if (state.recentActivitySetsPoll.hasWriteLock) {
        return state;
      }
      return setRecentActivitySetsData(state, params.activitySets);
    case actionOptimisticActivitySetUpdate.type: {
      const updatedActivitySet = params.activitySet;
      const noAssociatedWorkOrderOp = !updatedActivitySet.workOrderOperationRef;

      const workOrderManagement = noAssociatedWorkOrderOp
        ? {
            ...state.workOrderManagement,
            currentWorkOrderOp: null,
          }
        : state.workOrderManagement;

      return {
        ...state,
        recentActivitySetsPoll: {
          ...state.recentActivitySetsPoll,
          hasWriteLock: true,
        },
        activitySets: {
          byRef: {
            ...state.activitySets.byRef,
            [params.activitySet.activitySetRef]: {
              ...state.activitySets.byRef[params.activitySet.activitySetRef],
              ...params.activitySet,
            },
          },
          allRefs: getOrderedActivitySetRefs({
            ...state.activitySets.byRef,
            [params.activitySet.activitySetRef]: params.activitySet,
          }),
        },
        workOrderManagement,
      };
    }
    case actionCanonicalActivitySetUpdate.type:
      return {
        ...state,
        recentActivitySetsPoll: {
          ...state.recentActivitySetsPoll,
          hasWriteLock: false,
        },
        activitySets: {
          byRef: {
            ...state.activitySets.byRef,
            [params.activitySet.activitySetRef]: {
              ...state.activitySets.byRef[params.activitySet.activitySetRef],
              ...params.activitySet,
            },
          },
          allRefs: getOrderedActivitySetRefs({
            ...state.activitySets.byRef,
            [params.activitySet.activitySetRef]: params.activitySet,
          }),
        },
      };
    case actionOptimisticActivitySetOpen.type: {
      const newActivitySet = params.activitySet;
      const noAssociatedWorkOrderOp = !newActivitySet.workOrderOperationRef;

      const workOrderManagement = noAssociatedWorkOrderOp
        ? {
            ...state.workOrderManagement,
            currentWorkOrderOp: null,
          }
        : state.workOrderManagement;

      return {
        ...state,
        recentActivitySetsPoll: {
          ...state.recentActivitySetsPoll,
          hasWriteLock: true,
        },
        activitySets: {
          byRef: {
            ...state.activitySets.byRef,
            [params.activitySet.activitySetRef]: params.activitySet,
          },
          allRefs: getOrderedActivitySetRefs({
            ...state.activitySets.byRef,
            // this will be the latest by default
            [params.activitySet.activitySetRef]: params.activitySet,
          }),
        },
        activities: {
          byRef: {
            ...state.activities.byRef,
            [params.activity.activityRef]: params.activity,
          },
          allRefs: [
            // this will be the latest by default
            params.activity.activityRef,
            ...state.activities.allRefs,
          ],
        },
        // we need to set this job data on open in case it does not exist on state
        jobs: {
          byId: {
            ...state.jobs.byId,
            [params.job.jobId]: params.job,
          },
          allIds: getOrderedJobIds({
            ...state.jobs.byId,
            [params.job.jobId]: params.job,
          }),
        },
        workOrderManagement,
      };
    }
    case actionCanonicalActivitySetOpen.type:
      return {
        ...state,
        recentActivitySetsPoll: {
          ...state.recentActivitySetsPoll,
          hasWriteLock: false,
        },
        activitySets: {
          byRef: {
            // remove local activity set with dummy ref
            ..._omit(state.activitySets.byRef, params.optimisticActivitySetRef),
            [params.activitySet.activitySetRef]: params.activitySet,
          },
          allRefs: [
            // insert valid activitySetRef from server res
            params.activitySet.activitySetRef,
            // remove local activity with dummy ref
            ..._without(
              state.activitySets.allRefs,
              params.optimisticActivitySetRef
            ),
          ],
        },
        activities: {
          byRef: {
            // remove local activity with dummy ref
            ..._omit(state.activities.byRef, params.optimisticActivityRef),
            [params.activity.activityRef]: params.activity,
          },
          allRefs: [
            // insert valid activityRef from server res
            params.activity.activityRef,
            // remove local activity with dummy ref
            ..._without(state.activities.allRefs, params.optimisticActivityRef),
          ],
        },
      };
    case actionCanonicalActivitySetDelete.type:
      return {
        ...state,
        recentActivitySetsPoll: {
          ...state.recentActivitySetsPoll,
          hasWriteLock: false,
        },
        activitySets: {
          byRef: {
            ..._omit(state.activitySets.byRef, params.activitySetRef),
          },
          allRefs: [
            ..._without(state.activitySets.allRefs, params.activitySetRef),
          ],
        },
        activities: {
          byRef: {
            ..._omitBy(state.activities.byRef, (act) => {
              return act.activitySetRef === params.activitySetRef;
            }),
          },
          allRefs: getOrderedActivityRefs({
            ..._omitBy(state.activities.byRef, (act) => {
              return act.activitySetRef === params.activitySetRef;
            }),
          }),
        },
      };
    case actionOptimisticActivitySetClose.type:
      return {
        ...state,
        recentActivitySetsPoll: {
          ...state.recentActivitySetsPoll,
          hasWriteLock: true,
        },
        activitySets: {
          byRef: {
            ...state.activitySets.byRef,
            [params.activitySet.activitySetRef]: {
              ...state.activitySets.byRef[params.activitySet.activitySetRef],
              ...params.activitySet,
            },
          },
          allRefs: getOrderedActivitySetRefs({
            ...state.activitySets.byRef,
            [params.activitySet.activitySetRef]: params.activitySet,
          }),
        },
        activities: {
          byRef: {
            ...state.activities.byRef,
            [params.activity.activityRef]: {
              ...state.activities.byRef[params.activity.activityRef],
              ...params.activity,
            },
          },
          allRefs: getOrderedActivityRefs({
            ...state.activities.byRef,
            [params.activity.activityRef]: params.activity,
          }),
        },
        workOrderManagement: {
          ...state.workOrderManagement,
          currentWorkOrderOp: null,
        },
      };
    case actionCanonicalActivitySetClose.type:
      return {
        ...state,
        recentActivitySetsPoll: {
          ...state.recentActivitySetsPoll,
          hasWriteLock: false,
        },
        activitySets: {
          byRef: {
            ...state.activitySets.byRef,
            [params.activitySet.activitySetRef]: {
              ...state.activitySets.byRef[params.activitySet.activitySetRef],
              ...params.activitySet,
            },
          },
          allRefs: getOrderedActivitySetRefs({
            ...state.activitySets.byRef,
            [params.activitySet.activitySetRef]: params.activitySet,
          }),
        },
        activities: {
          byRef: {
            ...state.activities.byRef,
            [params.activity.activityRef]: {
              ...state.activities.byRef[params.activity.activityRef],
              ...params.activity,
            },
          },
          allRefs: getOrderedActivityRefs({
            ...state.activities.byRef,
            [params.activity.activityRef]: params.activity,
          }),
        },
      };
    case actionCanonicalActivitySetReopen.type:
      return {
        ...state,
        recentActivitySetsPoll: {
          ...state.recentActivitySetsPoll,
          hasWriteLock: false,
        },
        activitySets: {
          byRef: {
            ...state.activitySets.byRef,
            [params.activitySet.activitySetRef]: {
              ...state.activitySets.byRef[params.activitySet.activitySetRef],
              ...params.activitySet,
            },
          },
          allRefs: getOrderedActivitySetRefs({
            ...state.activitySets.byRef,
            [params.activitySet.activitySetRef]: params.activitySet,
          }),
        },
        activities: {
          byRef: {
            ...state.activities.byRef,
            [params.activity.activityRef]: {
              ...state.activities.byRef[params.activity.activityRef],
              ...params.activity,
            },
          },
          allRefs: getOrderedActivityRefs({
            ...state.activities.byRef,
            [params.activity.activityRef]: params.activity,
          }),
        },
      };
    case actionOptimisticActivityCreate.type:
      return {
        ...state,
        recentActivitySetsPoll: {
          ...state.recentActivitySetsPoll,
          hasWriteLock: true,
        },
        activities: {
          byRef: {
            ...state.activities.byRef,
            [params.activity.activityRef]: params.activity,
          },
          allRefs: [
            // this will be the latest by default
            params.activity.activityRef,
            ...state.activities.allRefs,
          ],
        },
      };
    case actionCanonicalActivityCreate.type:
      return {
        ...state,
        recentActivitySetsPoll: {
          ...state.recentActivitySetsPoll,
          hasWriteLock: false,
        },
        activities: {
          byRef: {
            // remove local activity with dummy ref
            ..._omit(state.activities.byRef, params.optimisticActivityRef),
            [params.activity.activityRef]: params.activity,
          },
          allRefs: [
            params.activity.activityRef,
            // remove local activity with dummy ref
            ..._without(state.activities.allRefs, params.optimisticActivityRef),
          ],
        },
      };
    case actionOptimisticActivitySwitch.type: // closes active and opens new on same boundary
      return {
        ...state,
        recentActivitySetsPoll: {
          ...state.recentActivitySetsPoll,
          hasWriteLock: true,
        },
        activities: {
          byRef: {
            ...state.activities.byRef,
            [params.optimisticActivity.activityRef]: {
              ...params.optimisticActivity,
            },
            ...(params.latestActivityRef
              ? {
                  [params.latestActivityRef]: {
                    ...state.activities.byRef[params.latestActivityRef],
                    end: params.optimisticActivity.start,
                  },
                }
              : {}),
          },
          allRefs: [
            // this will be the latest by default
            params.optimisticActivity.activityRef,
            ...state.activities.allRefs,
          ],
        },
      };
    case actionCanonicalActivitySwitch.type:
      return {
        ...state,
        recentActivitySetsPoll: {
          ...state.recentActivitySetsPoll,
          hasWriteLock: false,
        },
        activities: {
          byRef: {
            // remove local activity with dummy ref
            ..._omit(state.activities.byRef, params.optimisticActivityRef),
            [params.activity.activityRef]: params.activity,
            ...(params.latestActivityRef
              ? {
                  [params.latestActivityRef]: {
                    ...state.activities.byRef[params.latestActivityRef],
                    end: params.optimisticActivity?.start || null,
                  },
                }
              : {}),
          },
          allRefs: [
            params.activity.activityRef, // this will be the latest by default
            // remove local activity with dummy ref
            ..._without(state.activities.allRefs, params.optimisticActivityRef),
          ],
        },
      };
    case actionCanonicalActivityUnswitch.type: // this would only happen if optimistic switch failed
      return {
        ...state,
        recentActivitySetsPoll: {
          ...state.recentActivitySetsPoll,
          hasWriteLock: false,
        },
        activities: {
          byRef: {
            ..._omit(state.activities.byRef, params.activityRef),
            ...(params.latestActivityRef
              ? {
                  [params.latestActivityRef]: {
                    ...state.activities.byRef[params.latestActivityRef],
                    end: params.optimisticActivity?.start,
                  },
                }
              : {}),
          },
          allRefs: [
            // remove local activity with dummy ref
            ..._without(state.activities.allRefs, params.activityRef),
          ],
        },
      };
    case actionOptimisticActivityUpdate.type:
      return {
        ...state,
        recentActivitySetsPoll: {
          ...state.recentActivitySetsPoll,
          hasWriteLock: true,
        },
        activities: {
          byRef: {
            ...state.activities.byRef,
            [params.activity.activityRef]: {
              ...state.activities.byRef[params.activity.activityRef],
              ...params.activity,
            },
          },
          allRefs: getOrderedActivityRefs({
            ...state.activities.byRef,
            [params.activity.activityRef]: params.activity,
          }),
        },
      };
    case actionCanonicalActivityUpdate.type:
      return {
        ...state,
        recentActivitySetsPoll: {
          ...state.recentActivitySetsPoll,
          hasWriteLock: false,
        },
        activities: {
          byRef: {
            ...state.activities.byRef,
            [params.activity.activityRef]: {
              ...state.activities.byRef[params.activity.activityRef],
              ...params.activity,
            },
          },
          allRefs: getOrderedActivityRefs({
            ...state.activities.byRef,
            [params.activity.activityRef]: params.activity,
          }),
        },
      };
    case actionCanonicalActivityDelete.type:
      return {
        ...state,
        recentActivitySetsPoll: {
          ...state.recentActivitySetsPoll,
          hasWriteLock: false,
        },
        activities: {
          byRef: {
            ..._omit(state.activities.byRef, params.activityRef),
          },
          allRefs: [..._without(state.activities.allRefs, params.activityRef)],
        },
      };
    case actionSetActivityTypes.type:
      return {
        ...state,
        activityTypes: { loading: false, data: params.activityTypes },
      };
    case actionSetOperators.type:
      return { ...state, operators: params.operators };
    case actionSetAlarms.type:
      return { ...state, alarms: params.alarms };

    case actionSetMachineGroups.type:
      return { ...state, machineGroups: params.machineGroups };

    case actionSetShiftStatusIntervals.type: {
      const shiftStatusIntervals = createStatusIntervalsFromExecution(
        params.executionIntervals
      );
      const statusIntervals = joinIntervalSets(
        state.statusIntervals,
        shiftStatusIntervals
      );
      return {
        ...state,
        statusIntervals,
      };
    }
    case actionSetDowntimeIntervals.type: {
      const downtimeIntervals = createStatusIntervalsFromExecution(
        params.executionIntervals
      );
      const downtimeStartDate = _get(
        _sortBy(params.executionIntervals, 'start'),
        '0.start'
      );

      addExtendedDowntime(params.lastNonIdleInterval, downtimeIntervals);

      const statusIntervals = joinIntervalSets(
        downtimeIntervals,
        state.statusIntervals
      );
      return {
        ...state,
        downtimeStartDate,
        statusIntervals,
      };
    }
    case actionSetScopeStatusIntervals.type: {
      const appStart = startOfAppHistory();
      const scopeStatusIntervals = createStatusIntervalsFromExecution(
        params.executionIntervals
      );
      const completeStatusIntervals = joinIntervalSets(
        state.statusIntervals,
        scopeStatusIntervals
      );
      const statusIntervals = _dropWhile(
        completeStatusIntervals,
        (interval) => {
          return interval.end && interval.end <= appStart;
        }
      );
      return {
        ...state,
        statusIntervals,
      };
    }
    case actionSetUtilizationHourlies.type:
      return {
        ...state,
        utilizationHourlies: {
          hourlies: params.hourlies,
          generatedAt: params.generatedAt,
        },
      };
    case actionSetLastPartTimestamp.type:
      return {
        ...state,
        lastPartTimestamp: params.lastPartTimestamp,
      };
    case actionSetPartCountEvents.type:
      return {
        ...state,
        partCountEvents: params.partCountEvents, // already sorted by dt asc in query
      };
    case actionSetPartCountBuckets.type: {
      const totalScheduledRawParts = _sumBy(params.scheduledPartBuckets, 'sum');
      const totalUnscheduledRawParts = _sumBy(
        params.unscheduledPartBuckets,
        'sum'
      );
      const totalScheduledCycles = _sumBy(params.scheduledPartBuckets, 'count');
      const totalUnscheduledCycles = _sumBy(
        params.unscheduledPartBuckets,
        'count'
      );
      return {
        ...state,
        totalScheduledRawParts,
        totalUnscheduledRawParts,
        totalRawParts: totalScheduledRawParts + totalUnscheduledRawParts,
        totalCycles: totalScheduledCycles + totalUnscheduledCycles,
        partCountBuckets: {
          loading: false,
          queryArgs: {
            // track args used for last res so selectors can ignore stale data
            startDate: params.startDate,
          },
          data: {
            scheduledPartBuckets: params.scheduledPartBuckets,
            unscheduledPartBuckets: params.unscheduledPartBuckets,
          },
        },
      };
    }
    case actionSetSetupRawParts.type: {
      return {
        ...state,
        setupRawParts: action.sum,
      };
    }
    case actionSetShiftSetupRawParts.type: {
      return {
        ...state,
        shiftSetupRawParts: action.sum,
      };
    }
    case actionSetPartAdjustmentTypes.type:
      return { ...state, partAdjustmentTypes: params.partAdjustmentTypes };
    case actionSetAnnotationTypes.type:
      return { ...state, annotationTypes: params.annotationTypes };
    case actionSetPartAdjustments.type:
      return { ...state, partAdjustments: params.partAdjustments };
    case actionCreatePartAdjustmentSuccess.type:
      return {
        ...state,
        partAdjustments: [...state.partAdjustments, params.partAdjustment],
      };
    case actionSetInitialData.type: {
      const machinePartKey = params.machinePartKey[0] || {};
      const { partCount } = machinePartKey;
      const partKeys = partCount
        ? [partCount]
        : params.metricPartKeys.map(({ key }) => {
            return key;
          });

      return {
        ...state,
        alarms: params.alarms,
        partKeys,
        loading: false,
        initialDataSet: true,
      };
    }
    case actionSetPopupStack.type:
      const newStack = [...state.popupStack, { ...params }];
      // each popup has an order property, set when we dispatch the action
      // popup component will be shown according to the order number
      // within the same popup order (workflow), popup will be shown according to trigger timestamp
      newStack.sort((a, b) => {
        return a.order === b.order
          ? isBefore(a.timestamp, b.timestamp)
            ? -1
            : 1
          : a.order - b.order;
      });

      if (params.popupType === 'downtime') {
        return {
          ...state,
          popupStack: newStack,
          popup: params,
        };
      } else {
        return {
          ...state,
          popupStack: newStack,
        };
      }
    case actionClosePopup.type:
      return {
        ...state,
        popup: null,
        popupStack: state.popupStack.filter((popup) => {
          return popup.popupType !== 'downtime';
        }),
      };
    case actionDismissPopup.type: {
      const popupType = params?.params;
      if (popupType === 'workflow' || popupType === 'message') {
        return {
          ...state,
          popupStack: state.popupStack.slice(1),
        };
        /* eslint-disable no-else-return */
      } else if (!state.popup) {
        return state;
      }
      // the last condition is popupType === 'downtime'
      return {
        ...state,
        popup: { ...state.popup, dismissed: true },
        popupStack: state.popupStack.filter((popup) => {
          return popup.popupType !== 'downtime';
        }),
      };
    }
    case actionTriggerDowntimeFlyup.type: {
      return {
        ...state,
        downtimeFlyup: { ...state.downtimeFlyup, triggered: true },
      };
    }
    case actionDismissDowntimeFlyup.type: {
      return {
        ...state,
        downtimeFlyup: { ...state.downtimeFlyup, dismissed: true },
      };
    }
    case actionClearDowntimeFlyup.type: {
      return { ...state, downtimeFlyup: initialState.downtimeFlyup };
    }
    case actionSetRecentOperatorRuns.type: {
      return {
        ...state,
        operatorRuns: _sortBy(params.operatorRuns, 'startAt'),
        recentOperatorRunsPoll: {
          isInitialPollLoading: false,
        },
      };
    }
    case actionSetAgentStatus.type: {
      if (params.status?.connected === true) {
        return {
          ...state,
          agentStatus: {
            connected: true,
            successiveFailures: 0,
          },
        };
      }

      const successiveFailures = state.agentStatus.successiveFailures + 1;
      return {
        ...state,
        agentStatus: {
          connected: successiveFailures < 3,
          successiveFailures,
        },
      };
    }
    case actionSetNetworkError.type:
      return {
        ...state,
        networkError: {
          ...state.networkError,
          [params.id]: {
            message: params.message,
            retries:
              _get(state, ['networkError', params.id, 'retries'], -1) + 1,
          },
        },
      };
    case actionUnsetNetworkError.type:
      return {
        ...state,
        networkError: {
          ...state.networkError,
          [params.id]: {
            message: false,
            retries: 0,
          },
        },
      };
    case actionRetryNetworkError.type:
      return {
        ...state,
        networkError: {
          ...state.networkError,
          [params.id]: {
            ...state.networkError[params.id],
            retries:
              _get(state, ['networkError', params.id, 'retries'], -1) + 1,
          },
        },
      };
    case actionUpdateConnectionStatus.type:
      if (params.status === state.connection.status) {
        return state;
      }
      if (params.status === 'connected') {
        params.disconnectedAt = null;
      }
      if (params.status !== 'connected' && !state.connection.disconnectedAt) {
        params.disconnectedAt = toISO();
      }
      return {
        ...state,
        connection: { ...state.connection, ...params },
      };
    case actionSetLatestHeartbeat.type:
      return {
        ...state,
        latestHeartbeat: {
          ...params,
          messageTime: toISO(),
        },
      };
    case actionToggleTraining.type:
      return { ...state, openTraining: params.toggleTraining };
    case actionSetActivitiesStatus.type:
      return {
        ...state,
        activitiesStatusSubscription: {
          loading: false,
          data: params.activitiesStatus,
        },
      };
    case actionSetProgramNameData.type:
      return {
        ...state,
        programNameSubscription: {
          loading: false,
          data: params.programNameData,
        },
      };
    case actionSetFirstPart.type: {
      const firstPart = params.firstPart[0] || { eventTime: null };
      const { eventTime: firstPartTimestamp } = firstPart;

      return {
        ...state,
        firstPartTimestamp,
      };
    }
    case actionSetWorkflows.type: {
      return {
        ...state,
        workflows: {
          ...state.workflows,
          loading: false,
          data: params.workflows.data,
        },
      };
    }
    case actionTriggerWorkflow.type: {
      return {
        ...state,
        workflows: {
          ...state.workflows,
          lastTriggered: params.workflow,
        },
      };
    }
    case actionSetCustomTabURL.type: {
      return {
        ...state,
        customTabs: [...state.customTabs, { url: params.url, loading: true }],
      };
    }
    case actionSetTabs.type: {
      const customTabs = params.tabs.filter((tab) => {
        return !!tab.url && tab.enabled;
      });
      return {
        ...state,
        tabs: {
          loading: false,
          data: params.tabs,
        },
        customTabs,
      };
    }
    case actionSetLatestPingTime.type: {
      return {
        ...state,
        latestPing: params.ping,
      };
    }
    case actionSetLocationRef.type: {
      return {
        ...state,
        company: {
          ...state.company,
          data: {
            ...state.company.data,
            locationRef: params.locationRef,
          },
        },
      };
    }
    case actionSetHealthData.type: {
      return {
        ...state,
        healthData: [...state.healthData, params.healthData],
      };
    }
    case actionOpenLanguageModal.type: {
      return {
        ...state,
        languageModalOpen: true,
      };
    }
    case actionCloseLanguageModal.type: {
      return {
        ...state,
        languageModalOpen: false,
      };
    }
    case actionSetLabsAuthorized.type: {
      return {
        ...state,
        labsAuthorized: params.labsAuthorized,
      };
    }
    // WORK ORDER MANAGEMENT: ////////////
    case actionSetMachineERPResources.type:
      return {
        ...state,
        machine: {
          ...state.machine,
          erpResources: params.resources,
          erpResourceId: params.erpResourceId,
        },
      };
    case actionOpenLaborTicketModal.type: {
      const { options } = params;

      return {
        ...state,
        workOrderManagement: {
          ...state.workOrderManagement,
          laborTicketModalOpen: true,
          laborTicketAction: params.laborTicketAction,
          redirectPath: options?.redirectPath || null,
          pendingActivityTypeRef: options?.pendingActivityTypeRef || null,
        },
      };
    }
    case actionSetPendingActivityTypeRef.type: {
      return {
        ...state,
        workOrderManagement: {
          ...state.workOrderManagement,
          pendingActivityTypeRef: params.pendingActivityTypeRef || null,
        },
      };
    }

    case actionCloseLaborTicketModal.type: {
      return {
        ...state,
        workOrderManagement: {
          ...state.workOrderManagement,
          laborTicketModalOpen: false,
          laborTicketAction: null,
        },
      };
    }
    case actionSetLaborTicketAction.type:
      return {
        ...state,
        workOrderManagement: {
          ...state.workOrderManagement,
          laborTicketAction: params.laborTicketAction,
        },
      };

    case actionSetLaborTickets.type: {
      const currentLaborTicket = params.laborTickets
        .sort((a, b) => {
          return a.clockIn.localeCompare(b.clockIn);
        })
        .find((laborTicket) => {
          return laborTicket.state === 'OPEN';
        });

      return {
        ...state,
        workOrderManagement: {
          ...state.workOrderManagement,
          laborTickets: params.laborTickets,
          currentLaborTicket: currentLaborTicket || null,
        },
      };
    }

    case actionSetCurrentWorkOrderOp.type: {
      return {
        ...state,
        workOrderManagement: {
          ...state.workOrderManagement,
          currentWorkOrderOp: action.workOrderOp,
          loadingCurrentWorkOrderOp: false,
        },
      };
    }
    case actionSetCurrentWorkOrderOpGoodParts.type: {
      return {
        ...state,
        workOrderManagement: {
          ...state.workOrderManagement,
          currentWorkOrderOp: {
            ...state.workOrderManagement.currentWorkOrderOp,
            totalGoodParts: action.goodParts,
            startAt: action.startAt,
          },
        },
      };
    }

    case actionSetDefaultManualEntry.type: {
      return {
        ...state,
        workOrderManagement: {
          ...state.workOrderManagement,
          defaultManualEntry: action.workOrder,
        },
      };
    }

    case actionSetLaborTicketActionLoading.type:
      return {
        ...state,
        workOrderManagement: {
          ...state.workOrderManagement,
          laborTicketActionLoading: action.loading,
        },
      };

    case actionSetWorkOrderOpActionLoading.type:
      return {
        ...state,
        workOrderManagement: {
          ...state.workOrderManagement,
          workOrderOpActionLoading: action.loading,
        },
      };

    case actionSetLaborTicketScopeAdjustment.type:
      return {
        ...state,
        workOrderManagement: {
          ...state.workOrderManagement,
          laborTicketScopeAdjustment: action.count,
        },
      };

    case actionSetScheduledTime.type:
      return {
        ...state,
        scheduledTime: {
          ...state.scheduledTime,
          loading: false,
          data: action.scheduledTime,
        },
      };

    case actionSetScheduledTimeEnabled.type:
      return {
        ...state,
        scheduledTime: {
          ...state.scheduledTime,
          isEnabled: action.isEnabled,
        },
      };

    default:
      return state;
  }
};
