import { createSelector } from 'reselect';
import _get from 'lodash/get';

import { getLaunchDarklyFlags } from 'lib/selectors/getLaunchDarklyFlags';
import { FLAG_KEYS } from 'lib/constants';
import { getIsAPMEnabled } from './getIsAPMEnabled';
import { getLatestActivitySet } from './getLatestActivitySet';
import { getCurrentWorkOrderOp } from './getCurrentWorkOrderOp';
import { getCurrentOperator } from './getCurrentOperator';
import { getCurrentLaborTicket } from './getLaborTickets';
import { getUnattendedOperatorPersonId } from './getUnattendedOperatorPersonId';

const getCanOpenLaborTicket = createSelector(
  [
    getIsAPMEnabled,
    getCurrentWorkOrderOp,
    getLatestActivitySet,
    getCurrentOperator,
  ],
  (isAPMEnabled, currentWorkOrderOp, latestActivitySet, currentOperator) => {
    return !!(
      !isAPMEnabled &&
      currentWorkOrderOp &&
      _get(latestActivitySet, 'closedAt') === null &&
      _get(currentOperator, 'erpId')
    );
  }
);

const getNeedsForceLogin = createSelector(
  [getIsAPMEnabled, getCurrentOperator, getLaunchDarklyFlags],
  (isAPMEnabled, currentOperator, flags) => {
    const isLaborTicketsEnabled = flags[FLAG_KEYS.LABOR_TICKET_ENABLED];
    return !!(!isAPMEnabled && !currentOperator && isLaborTicketsEnabled);
  }
);

const getCanCloseLaborTicket = createSelector(
  [
    getIsAPMEnabled,
    getCurrentOperator,
    getCurrentLaborTicket,
    getCurrentWorkOrderOp,
    getUnattendedOperatorPersonId,
  ],
  (
    isAPMEnabled,
    currentOperator,
    currentLaborTicket,
    currentWorkOrderOp,
    unattendedPersonId
  ) => {
    return !!(
      !isAPMEnabled &&
      (_get(currentOperator, 'erpId') ||
        _get(currentLaborTicket, 'personId') === unattendedPersonId) &&
      currentLaborTicket &&
      currentWorkOrderOp
    );
  }
);

export { getCanOpenLaborTicket, getCanCloseLaborTicket, getNeedsForceLogin };
