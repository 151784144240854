// React and Hooks
import React from 'react';
// Actions
// Selectors
// Utils
import PropTypes from 'prop-types';
// Components

import { Modal, Icon, palette } from '@m12s/component-library';
import { falTimes } from 'lib/icons';
import {
  IconWrapper,
  modalStyles,
  headerStyles,
  detailsStyles,
  buttonStyles,
  ModalButtons,
} from './styled';

/**
 * LaborTicketBaseActionModal component renders a modal with customizable header, details, and buttons.
 *
 * @param {Object} props - Component properties.
 * @param {boolean} props.isOpen - Determines if the modal is open.
 * @param {function} props.onClose - Function to call when the modal is closed.
 * @param {React.ReactNode} props.header - Content to display in the modal header.
 * @param {React.ReactNode} props.details - Content to display in the modal details section.
 * @param {React.ReactNode|React.ReactNode[]} props.buttons - Buttons to display in the modal footer.
 *
 * @returns {JSX.Element} The rendered modal component.
 */
const LaborTicketBaseActionModal = ({
  isOpen,
  onClose,
  header,
  details,
  buttons,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      modalStyles={modalStyles}
      headerStyles={headerStyles}
      detailsStyles={detailsStyles}
      buttonsStyles={buttonStyles}
      headerIcon={
        <IconWrapper onClick={onClose}>
          <Icon
            size="2rem"
            color={palette.Grey700}
            icon={falTimes}
            cursor="pointer"
          />
        </IconWrapper>
      }
      header={header}
      details={details}
      buttons={<ModalButtons>{buttons}</ModalButtons>}
    />
  );
};

LaborTicketBaseActionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.node.isRequired,
  buttons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  details: PropTypes.node,
};

export default LaborTicketBaseActionModal;
